/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable import/prefer-default-export */
/* eslint-disable no-underscore-dangle */

import Swal from 'sweetalert2';
import { saveAs } from 'file-saver';
import { stomataAPI } from '../services/baseAPI';

const uploadFileReport = async (token, params, report, reporter) => {
  Swal.fire({
    title: 'Please wait...',
    text: 'Your upload is in progress',
    allowOutsideClick: false,
    didOpen: () => {
      Swal.showLoading();
    },
  });

  const reporterFirstName = reporter.first_name
    ?.toLowerCase()
    .replace(/ /g, '_');
  const reporterLastName = reporter.last_name?.toLowerCase().replace(/ /g, '_');
  const reporterName = `${reporterFirstName}-${reporterLastName}`;

  const formData = new FormData();

  formData.append('id', params._id);
  formData.append('document', report.file);
  formData.append('typeFile', report.typeFile.value);
  formData.append('farmName', params.farmInfo.name);
  formData.append('name', params.name);
  formData.append('remarks', params.remarks);
  formData.append('reporter', reporterName);

  await stomataAPI
    .post('/api/v1/tasks/upload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        token,
      },
    })
    .then(res => {
      Swal.close();
      Swal.fire('Saved!', 'Report has been created', 'success');
    })
    .catch(err => {
      console.error(err);
      Swal.close();
      Swal.fire('Changes are not saved', 'there is something wrong', 'error');
    });
};

const downloadFileReport = async (token, report) => {
  Swal.fire({
    title: 'Please wait...',
    text: 'Your download is in progress',
    allowOutsideClick: false,
    didOpen: () => {
      Swal.showLoading();
    },
  });

  await stomataAPI
    .get(`/api/v1/tasks/download/${report.fileId}`, {
      headers: {
        token, 
      },
      responseType: 'blob',
    })
    .then(response => {
      Swal.close(); // Close the loading indicator

      const blob = new Blob([response.data]);
      saveAs(blob, report.fileName);

      Swal.fire('Downloaded!', 'Your file has been downloaded', 'success');
    })
    .catch(err => {
      console.error(err);
      Swal.close();
      Swal.fire('Error', 'Failed to download the file', 'error');
    });
};

const deleteFileReport = async (token, task, report) => {
  Swal.fire({
    title: 'Please wait...',
    text: 'Your delete is in progress',
    allowOutsideClick: false,
    didOpen: () => {
      Swal.showLoading();
    },
  });

  await stomataAPI
    .delete(`/api/v1/tasks/delete/${report.fileId}`, {
      headers: {
        token,
      },
      data: {
        typeFile: report.type,
        taskId: task._id,
      },
    })
    .then(res => {
      Swal.close();
      Swal.fire('Deleted!', 'Report has been deleted', 'success');
    })
    .catch(err => {
      console.error(err);
      Swal.close();
      Swal.fire('Changes are not saved', 'there is something wrong', 'error');
    }).finally(
      // refetch agronomist
      
    );
};

export {uploadFileReport, downloadFileReport, deleteFileReport};
