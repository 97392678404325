import axios from 'axios';

// Production
const oldURI = 'https://habibigarden.com/dashboard/public/api';
const newURI = 'https://api.habibigarden.com';
const kelanaURI = 'https://api-kelana.habibigarden.cloud';
const stomataURI = 'https://staging-stomata-be.habibigarden.cloud/';
const awsKelanaURI =
  'https://7bqnkxh22a.execute-api.ap-southeast-1.amazonaws.com/Prod';
const awsHabibigardenURI =
  'https://c0dtyd3z10.execute-api.ap-southeast-1.amazonaws.com/prod';

// Local
// const newURI = 'http://localhost:3001';
// const kelanaURI = 'http://localhost:3002';
// const stomataURI = 'http://localhost:3004';

const oldAPI = axios.create({baseURL: oldURI});
const newAPI = axios.create({baseURL: newURI});
const kelanaAPI = axios.create({baseURL: kelanaURI});
const stomataAPI = axios.create({baseURL: stomataURI});
const awsKelanaAPI = axios.create({baseURL: awsKelanaURI});
const awsHabibigardenAPI = axios.create({baseURL: awsHabibigardenURI});

// instance.defaults.headers.common['Authorization'] = token;
// instance.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

export {
  awsKelanaAPI,
  awsHabibigardenAPI,
  oldAPI,
  newAPI,
  stomataAPI,
  kelanaAPI,
  oldURI,
  newURI,
  kelanaURI,
  stomataURI,
};
