/* eslint-disable no-underscore-dangle */
/* eslint-disable no-alert */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable react/jsx-props-no-spreading */
import React, {useState, useEffect, useCallback, useRef} from 'react';
import {Link, useParams, useHistory} from 'react-router-dom';
import {GoogleMap, Marker, useLoadScript} from '@react-google-maps/api';
import {useForm} from 'react-hook-form';
import axios from 'axios';
import Select from 'react-select';
import Swal from 'sweetalert2';
import moment from 'moment';
import DatePicker from 'react-datepicker';

import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {CKEditor} from '@ckeditor/ckeditor5-react';
import {ModalBottom} from '../../components/Modal';

import {stomataAPI, newAPI, newURI} from '../../services/baseAPI';
import Icon from '../../components/Icon';
import Card from '../../components/SimpleCard';
import LoadingView from '../../components/Loading';
import useUserStore from '../../stores/Users';
import './FarmDetail.css';
import {HabibiTable, SelectColumnFilter} from '../../components/HabibiTable';

// components

import Current from './dataview/Current';
import SensorGroundNew from './dataview/SensorGroundNew';
import SensorAwsNew from './dataview/SensorAwsNew';
import SensorDoseNew from './dataview/SensorDoseNew';
import Sensor from './dataview/Sensor';
import Growth from './dataview/Growth';
import Report from './report/Report';
import {
  deleteFileReport,
  downloadFileReport,
  uploadFileReport,
} from '../../actions/farmController';

// components report
// import CompareReport from './datareport/CompareReport';
const labelTasks = [
  {value: 'mingguan', label: 'Mingguan'},
  {
    value: 'bulanan_bukukebun',
    label: 'Bulanan - Buku Kebun',
  },
  {value: 'bulanan_timeline', label: 'Bulanan - Timeline'},
  {
    value: 'bulanan_lapcuaca',
    label: 'Bulanan - Lap. Cuaca',
  },
  {
    value: 'bulanan_lapsistem',
    label: 'Bulanan - Lap. Sistem',
  },
  {
    value: 'bulanan_datasensor',
    label: 'Bulanan - Data Sensor',
  },
  {
    value: 'bulanan_forecasting',
    label: 'Bulanan - Ramalan Cuaca',
  },
  {value: 'musim_aut', label: 'Musim - AUT'},
  {value: 'musim_impact', label: 'Musim - Impact'},
  {value: 'musim_rekapan', label: 'Musim - Rekap Laporan'},
];

function FarmDetail() {
  const {farmId} = useParams();
  const history = useHistory();
  const ADMIN = 1;
  const OWNER = 2;
  const GUEST = 5;
  const EMPLOYEES = 6 || 7;
  const AGRONOMIST = 6;
  const token = useUserStore(state => state.token);
  const user = useUserStore(state => state.user);
  const {
    register,
    handleSubmit,
    onBlur,
    errors,
    setValue,
    setError,
    clearErrors,
  } = useForm();

  const [menu, setMenu] = useState('info');
  const [menuSensor, setMenuSensor] = useState('current');
  const [menuReport, setMenuReport] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [farmDetail, setFarmDetail] = useState([]);
  const [farmDevice, setFarmDevice] = useState(null);
  const [farmZone, setFarmZone] = useState([]);
  const [tempFarmZone, setTempFarmZone] = useState([]);
  const [newZone, setNewZone] = useState({
    name_zone: '',
    area: '0',
    length: '0',
    plants_distance: '0',
    population: '0',
    hbbcooling_id: '',
    mechanical_id: '',
    plants_id: '',
    tgl_tanam: '',
    monit_env: false,
    monit_media: false,
    monit_ph: false,
    monit_npk: false,
  });
  const [farm, setFarm] = useState({
    name: 'FARMNAME',
    code_farm: 'FARMCODE',
    phone: '080989999',
    provider: null,
    tgl_isi_pulsa: null,
    city: 'Bandung',
    img: 'untitled.jpeg',
    latitude: '0',
    longitude: '0',
  });
  // const [Latlng, setLatLng] = useState({
  //   lat: -0.789275,
  //   lng: 113.921327,
  // });
  const [latitude, setLatitude] = useState(-0.789275);
  const [long, setLong] = useState(113.921327);
  const [listMechanics, setListMechanics] = useState([]);
  const [mecZone, setMecZone] = useState([]);
  const [plantZone, setPlantZone] = useState([]);
  const [listPlant, setListPlant] = useState([]);
  const [coolingZone, setCoolingZone] = useState([]);
  const [coolingList, setCoolingList] = useState([
    {name: 'hbbcooling_id', value: 1, label: 'Nozzle Fourway'},
    {name: 'hbbcooling_id', value: 2, label: 'Misting Nozzle'},
    {name: 'hbbcooling_id', value: 3, label: 'Exhaust'},
  ]);

  const time = new Date();

  const [ground, setGround] = useState({
    temp: [20, 21, 21, 21, 21, 21, 21, 20, 20, 20],
    mois: [90, 90, 89, 89, 89, 89, 88, 88, 87, 87],
    nutrient: [400, 400, 401, 402, 403, 403, 403, 403, 403, 403],
    light: [
      10000, 10030, 10030, 10040, 10030, 10030, 10030, 10030, 10030, 10030,
    ],
    time: [
      `${time.getUTCDate() - 8}/${
        time.getUTCMonth() + 1
      }/${time.getUTCFullYear()}`,
      `${time.getUTCDate() - 7}/${
        time.getUTCMonth() + 1
      }/${time.getUTCFullYear()}`,
      `${time.getUTCDate() - 6}/${
        time.getUTCMonth() + 1
      }/${time.getUTCFullYear()}`,
      `${time.getUTCDate() - 5}/${
        time.getUTCMonth() + 1
      }/${time.getUTCFullYear()}`,
      `${time.getUTCDate() - 4}/${
        time.getUTCMonth() + 1
      }/${time.getUTCFullYear()}`,
      `${time.getUTCDate() - 3}/${
        time.getUTCMonth() + 1
      }/${time.getUTCFullYear()}`,
      `${time.getUTCDate() - 2}/${
        time.getUTCMonth() + 1
      }/${time.getUTCFullYear()}`,
      `${time.getUTCDate() - 1}/${
        time.getUTCMonth() + 1
      }/${time.getUTCFullYear()}`,
      `${time.getUTCDate()}/${time.getUTCMonth() + 1}/${time.getUTCFullYear()}`,
      `${time.getUTCDate() + 1}/${
        time.getUTCMonth() + 1
      }/${time.getUTCFullYear()}`,
    ],
  });

  // const [aws, setAws] = useState({
  //   temp: [20, 21, 21, 21, 21, 21, 21, 20, 20, 20],
  //   hum: [90, 90, 89, 89, 89, 89, 88, 88, 87, 87],
  //   light: [10000, 10030, 10030, 10040, 10030, 10030, 10030, 10030, 10030, 10030],
  //   pres: [3241, 3241, 3241, 3241, 3242, 3243, 3244, 3244, 3244, 3245],
  //   rainh: [1038, 1038, 1038, 1038, 1038, 1038, 1038, 1038, 1038, 1038],
  //   raind: [1038, 1038, 1038, 1038, 1038, 1038, 1038, 1038, 1038, 1038],
  //   winda: [0.14, 0.14, 0.14, 0.14, 0.14, 0.14, 0.13, 0.13, 0.13, 0.13],
  //   windm: [1.12, 1.12, 1.12, 1.12, 1.12, 1.12, 1.13, 1.13, 1.13, 1.13],
  //   time: [
  //     `${time.getUTCDate() - 8}/${time.getUTCMonth() + 1}/${time.getUTCFullYear()}`,
  //     `${time.getUTCDate() - 7}/${time.getUTCMonth() + 1}/${time.getUTCFullYear()}`,
  //     `${time.getUTCDate() - 6}/${time.getUTCMonth() + 1}/${time.getUTCFullYear()}`,
  //     `${time.getUTCDate() - 5}/${time.getUTCMonth() + 1}/${time.getUTCFullYear()}`,
  //     `${time.getUTCDate() - 4}/${time.getUTCMonth() + 1}/${time.getUTCFullYear()}`,
  //     `${time.getUTCDate() - 3}/${time.getUTCMonth() + 1}/${time.getUTCFullYear()}`,
  //     `${time.getUTCDate() - 2}/${time.getUTCMonth() + 1}/${time.getUTCFullYear()}`,
  //     `${time.getUTCDate() - 1}/${time.getUTCMonth() + 1}/${time.getUTCFullYear()}`,
  //     `${time.getUTCDate()}/${time.getUTCMonth() + 1}/${time.getUTCFullYear()}`,
  //     `${time.getUTCDate() + 1}/${time.getUTCMonth() + 1}/${time.getUTCFullYear()}`,
  //   ],
  // });

  const [parameter, setParameter] = useState([]);
  const [idGrowth, setIdGrowth] = useState('kos');
  const [plantName, setPlantName] = useState([]);
  const [truePlant, setTruePlant] = useState([]);
  const [handleGrowthId, setHandleGrowthId] = useState([]);
  const [dataPlant, setDataPlant] = useState([]);
  const [stateParam, setStateParam] = useState([]);
  const [dataFarm, setDataFarm] = useState([]);
  const [resellect, setResellect] = useState(0);
  const [device, setDevice] = useState({
    grow: null,
    dose: null,
    hws: null,
    hwq: null,
    hws_climate: null,
    cam: {
      cam_status: null,
      cam_master: null,
    },
    link: {
      link_status: null,
      link_type: null,
    },
    bardi: {
      bardi_status: null,
      bardi_master: null,
    },
  });
  const [forecast, setForecast] = useState(null);
  const [detailFarm, setDetailFarm] = useState({
    internet: null,
    method_purchasing: null,
    water_sources: null,
    field_type: null,
    link_type: null,
  });

  const onLoad = marker => {
    console.log('marker: ', marker);
  };
  const [imagePreview, setImagePreview] = useState(null);
  const [image, setImage] = useState(null);
  const [users, setUsers] = useState({owner: [], farmer: []});
  const [defaultUsers, setDefaultUsers] = useState(null);
  const {isLoaded, loadError} = useLoadScript({
    googleMapsApiKey: 'AIzaSyD-FqHubInKsX5Culh_eyNN0NRQbB9ePFs',
  });

  // editan hasani start

  const [dataLM, setDataLM] = useState([]);
  const [berhasil, setBerhasil] = useState(null);
  const [dataSelectTanaman, setdataSelectTanaman] = useState(null);
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;

  const [fileReport, setFileReport] = useState({});
  const [tasks, setTasks] = useState([]);
  const [chooseTasks, setChooseTasks] = useState({
    task: null,
    total: null,
  });
  const [activeTask, setActiveTask] = useState(null);
  const [listTasks, setListTasks] = useState([
    // {
    //   name: null,
    //   taskCode: null,
    //   farmInfo: null,
    //   todos: null,
    //   remarks: null,
    //   startedAt: null,
    //   endedAt: null,
    // },
  ]);
  const [employees, setEmployees] = useState([]);
  const [chooseEmployees, setChooseEmployees] = useState([]);
  const [selectedEmployees, setSelectedEmployees] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalOption, setModalOption] = useState(null);
  const [documentList, setDocumentList] = useState([]);

  // ambil data laporan mingguan start
  const getWeeklyReport = async () => {
    await newAPI
      .get(`/v3/farms/${farmId}/weekly_report`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(res => {
        setDataLM(res.data);
      });
  };
  // ambil data laporan mingguan end

  // tombol action start
  const handleActionLM = (farmID, reportId) => (
    <div className="text-end">
      <span className="dropdown">
        <button
          type="button"
          className="btn dropdown-toggle align-text-top"
          data-bs-boundary="viewport"
          data-bs-toggle="dropdown">
          Actions
        </button>
        <div className="dropdown-menu dropdown-menu-end">
          <Link
            className="dropdown-item"
            to={`/farm/${farmID}/detail/${reportId}/preview`}>
            Preview
          </Link>
          <Link
            className="dropdown-item"
            to={`/farm/${farmID}/detail/${reportId}/edit`}>
            Edit
          </Link>
          <button
            type="button"
            className="dropdown-item"
            onClick={() => {
              axios.get(`${newURI}/delete/${reportId}`);
              getWeeklyReport();
            }}>
            Delete
          </button>
        </div>
      </span>
    </div>
  );
  // tombol action end

  // ambil data tanaman untuk select tanaman di form start
  const fetchPlant = useCallback(async () => {
    setIsLoading(true);
    await newAPI
      .get('/plants')
      .then(res => setdataSelectTanaman(res.data))
      .catch(error => console.log(`error ${error}`))
      .finally(setIsLoading(false));
  }, []);
  // ambil data tanaman untuk select tanaman di form end

  useEffect(() => {
    register('kendalaContent');
    register('budidayaContent');
    register('kesimpulanContent');
    register('tanggalLaporan');
    getWeeklyReport();
    fetchPlant();
  }, []);

  const insertLaporanMingguan = async params => {
    const formData = new FormData();

    formData.append('farmId', farmId);

    // masukkan tanggal satu per satu
    Object.values(params.tanggalLaporan).forEach(tanggal => {
      formData.append('tanggalLaporan', tanggal);
    });

    formData.append('selectTanaman', params.selectTanaman);
    formData.append('sistemKebun', params.sistemKebun);
    formData.append('habibiClimate', params.habibiClimate);
    formData.append('faseTanaman', params.faseTanaman);
    formData.append('produksiKebun', params.produksiKebun);
    formData.append('hst', params.hst);
    formData.append('jumlahTanaman', params.jumlahTanaman);
    formData.append(
      'kendala',
      params.kendalaContent ? params.kendalaContent : '<br/>'
    );
    formData.append(
      'budidaya',
      params.budidayaContent ? params.budidayaContent : '<br/>'
    );
    formData.append(
      'kesimpulanContent',
      params.kesimpulanContent ? params.kesimpulanContent : '<br/>'
    );

    formData.append('image', params.gambarLaporan[0]);

    await newAPI
      .post(`/v3/farms/${farmId}/weekly_report`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      })
      .then(async res => {
        await getWeeklyReport();
        Swal.fire('Saved!', 'weekly report has been created', 'success');
      })
      .catch(err => {
        Swal.fire('Changes are not saved', 'there is something wrong', 'error');
      });
  };

  // select tanaman start
  const SelectTanaman = datatanaman =>
    datatanaman.map(data => (
      <option key={data.id} value={`${data.id},${data.plants_name}`}>
        {data.plants_name}
      </option>
    ));
  // select tanaman end

  // end editan hasani

  const fetchForecast = async (lat, lng) => {
    await axios
      .get(
        `https://api.weather.com/v3/wx/forecast/daily/5day?geocode=${lat},${lng}&units=m&language=id-ID&format=json&apiKey=4f2ab242ad04449faab242ad04849fb6`
      )
      .then(res => {
        setForecast(res.data.daypart[0]);
      })
      .catch(error => {
        console.log(`error ${error}`);
      });
  };

  const fetchFarmInfo = useCallback(async () => {
    setIsLoading(true);
    clearErrors('farm_code_used');

    const tokenAuth = `Bearer ${token}`;
    await axios
      .all([
        newAPI.get(`/v3/farms/${farmId}`, {
          headers: {Authorization: tokenAuth},
        }),
        newAPI.get(`/v3/farms/zone/${farmId}`, {
          headers: {Authorization: tokenAuth},
        }),
        newAPI.get(`/v3/mechanical`),
      ])
      .then(response => {
        const allData = response[0].data.data;
        const dataZona = response[1].data;
        const zone = dataZona;
        const dataMec = response[2].data[0];

        const mechData = [];
        for (let i = 0; i < zone.length; i += 1) {
          for (let index = 0; index < dataMec.length; index += 1) {
            if (parseInt(zone[i].mechanical_id, 10) === dataMec[index].id) {
              mechData.push({
                name: 'mechanical_id',
                value: dataMec[index].id,
                label: dataMec[index].mechanical_name,
              });
            }
          }
        }

        setMecZone(mechData);
        setFarm(allData.farms);
        setFarmDetail(allData.zones);
        setFarmZone(zone);
        setLatitude(parseFloat(allData.farms.latitude));
        setLong(parseFloat(allData.farms.longitude));

        fetchForecast(
          parseFloat(allData.farms.latitude),
          parseFloat(allData.farms.longitude)
        );

        setIsLoading(false);
      })
      .catch(error => {
        console.log(`error ${error}`);
        setIsLoading(false);
      });
  }, []);

  const fetchFarmEdit = async () => {
    setIsLoading(true);
    register('owner');
    register('farmer');
    register('water_sources');
    register('internet');
    register('status');
    register('method_purchasing');
    register('field_type');
    await axios
      .all(
        [
          newAPI.get('v3/users/owner'),
          newAPI.get('v3/users/farmer'),
          newAPI.get(`/v3/farms/${farmId}/edit`),
        ],
        {
          headers: {Authorization: `Bearer ${token}`},
        }
      )
      .then(res => {
        const ownerData = res[0].data;
        const farmerData = res[1].data;
        const arrayOwner = [];
        const arrayFarmer = [];

        setFarmDevice(res[2].data);
        ownerData.forEach(e => {
          arrayOwner.push({
            value: e.id,
            label: `${e.first_name} ${e.last_name}`,
          });
        });
        farmerData.forEach(e => {
          arrayFarmer.push({
            value: e.id,
            label: `${e.first_name} ${e.last_name}`,
          });
        });
        arrayFarmer.push({
          label: 'Tidak Ada',
          value: 0,
        });
        setUsers({owner: arrayOwner, farmer: arrayFarmer});
        const {farms, farmer, owner, dataDevice, hws} = res[2].data;
        const deffarmer = [];
        if (farmer.length !== 0) {
          deffarmer.push({
            label: `${farmer[0].first_name} ${farmer[0].last_name}`,
            value: farmer[0].id,
          });
        } else {
          deffarmer.push({
            label: 'Tidak Ada',
            value: 0,
          });
        }

        setDefaultUsers({
          owner: arrayOwner.find(e => e.value === owner[0].id),
          farmer: deffarmer,
        });

        const purchase = [];
        if (res[2].data.farms[0].method_purchasing === 'beli') {
          purchase.push({value: 'beli', label: 'Beli'});
        } else if (res[2].data.farms[0].method_purchasing === 'sewa') {
          purchase.push({value: 'sewa', label: 'Sewa'});
        } else if (res[2].data.farms[0].method_purchasing === 'yarnen') {
          purchase.push({value: 'yarnen', label: 'Bayar Panen'});
        } else {
          purchase.push(null);
        }

        const water = [];
        if (res[2].data.farms[0].water_sources === 'sumur') {
          water.push({label: 'Sumur', value: 'sumur'});
        } else if (res[2].data.farms[0].water_sources === 'pdam') {
          water.push({label: 'PDAM', value: 'pdam'});
        } else if (res[2].data.farms[0].water_sources === 'sungai') {
          water.push({label: 'Sungai', value: 'sungai'});
        } else if (res[2].data.farms[0].water_sources === 'bak') {
          water.push({label: 'Bak Penampungan', value: 'bak'});
        } else {
          water.push(null);
        }

        const inter = [];
        if (res[2].data.farms[0].internet === 'wifi') {
          inter.push({label: 'Wi-Fi', value: 'wifi'});
        } else if (res[2].data.farms[0].internet === 'hbblink') {
          inter.push({label: 'Habibi Link', value: 'hbblink'});
        } else {
          inter.push({value: '', label: ''});
        }

        const farmType = [];
        if (res[2].data.farms[0].field_type === 'indoor') {
          farmType.push({label: 'Indoor', value: 'indoor'});
        } else if (res[2].data.farms[0].field_type === 'outdoor') {
          farmType.push({label: 'Outdoor', value: 'indoor'});
        } else {
          farmType.push(null);
        }

        setDetailFarm({
          internet: inter[0],
          water_sources: water,
          method_purchasing: purchase,
          field_type: farmType,
        });
        setFarmDevice(res[2].data);
        setImagePreview(`${newURI}/farms/${farms[0].img}`);
        setValue('farmer', farmer.length === 0 ? 0 : farmer[0].id);
        setValue('owner', owner[0].id);
        setValue('internet', inter[0]);
        setValue('water_sources', water[0]);
        setValue('method_purchasing', purchase[0]);
        setValue('field_type', farmType[0]);
        setValue('status', res[2].data.farms[0].status);

        const countHws = dataDevice.find(e => e.id.indexOf('AWS') > -1)
          ? true
          : null;
        const countDose = dataDevice.find(e => e.id.indexOf('DOSE') > -1)
          ? true
          : null;
        const countHWQ = dataDevice.find(e => e.id.indexOf('WATERQUALITY') > -1)
          ? true
          : null;
        const countGrow = dataDevice.find(e => e.id.indexOf('CONFIG') > -1)
          ? true
          : null;
        const countZone = dataDevice.filter(e => e.id.indexOf('ZONA') > -1)
          ? dataDevice.filter(e => e.id.indexOf('ZONA') > -1).length
          : 0;
        const listDevice = res[2].data.farms[0].list_device;
        const linkType = [];
        if (listDevice !== null) {
          const deviceList = JSON.parse(listDevice);
          const feature = deviceList.link.link_type;
          if (feature !== null) {
            for (let i = 0; i < feature.length; i += 1) {
              if (feature[i] === 'dashboard') {
                linkType.push({value: 'dashboard', label: 'Dashboard'});
              } else if (feature[i] === 'apk') {
                linkType.push({value: 'apk', label: 'Aplikasi'});
              }
            }
          }
          setDevice({
            grow: countGrow,
            dose: countDose,
            hwq: countHWQ,
            hws: countHws,
            hws_climate: deviceList.hws_climate,
            cam: {
              cam_status: deviceList.cam.cam_status,
              cam_master: deviceList.cam.cam_master,
            },
            link: {
              link_status: deviceList.link.link_status,
              link_type: linkType,
            },
            bardi: {
              bardi_status: deviceList.bardi.bardi_status,
              bardi_master: deviceList.bardi.bardi_master,
            },
          });
        } else {
          // linkType.push(null)
          setDevice({
            grow: countGrow,
            dose: countDose,
            hwq: countHWQ,
            hws: countHws,
            hws_climate: false,
            cam: {
              cam_status: false,
              cam_master: null,
            },
            link: {
              link_status: false,
              link_type: null,
            },
            bardi: {
              bardi_status: false,
              bardi_master: null,
            },
          });
        }
      })
      .catch(error => console.log(`error ${error}`))
      .finally(() => setIsLoading(false));
  };

  const fetchZoneEdit = async () => {
    setIsLoading(true);

    const tokenAuth = `Bearer ${token}`;
    await axios
      .all([
        newAPI.get(`/v3/mechanical`),
        newAPI.get(`/v3/farms/zone/${farmId}`, {
          headers: {Authorization: tokenAuth},
        }),
        newAPI.get(`/v3/farms/${farmId}`, {
          headers: {Authorization: tokenAuth},
        }),
        newAPI.get(`/plants`),
      ])
      .then(res => {
        // Data Zona
        const dataMec = res[0].data[0];
        const dataZona = res[1].data;
        const zone = dataZona;
        const plant = res[3].data;

        const mechData = [];
        for (let i = 0; i < zone.length; i += 1) {
          for (let index = 0; index < dataMec.length; index += 1) {
            if (parseInt(zone[i].mechanical_id, 10) === dataMec[index].id) {
              mechData.push({
                name: 'mechanical_id',
                value: dataMec[index].id,
                label: dataMec[index].mechanical_name,
              });
            }
          }
        }
        // Data all Mekanikal
        const arrMec = [];
        dataMec.forEach(item => {
          arrMec.push({
            name: 'mechanical_id',
            value: item.id,
            label: item.mechanical_name,
          });
        });

        const plantData = [];
        for (let i = 0; i < zone.length; i += 1) {
          for (let index = 0; index < plant.length; index += 1) {
            if (parseInt(zone[i].plants_id, 10) === plant[index].id) {
              plantData.push({
                name: 'plants_id',
                value: plant[index].id,
                label: plant[index].plants_name,
              });
            }
          }
        }
        const arrPlant = [];
        plant.forEach(item => {
          arrPlant.push({
            name: 'plants_id',
            value: item.id,
            label: item.plants_name,
          });
        });

        const coolingData = [];
        for (let i = 0; i < zone.length; i += 1) {
          for (let index = 0; index < coolingList.length; index += 1) {
            if (
              parseInt(zone[i].hbbcooling_id, 10) === coolingList[index].value
            ) {
              coolingData.push({
                name: 'hbbcooling_id',
                value: coolingList[index].value,
                label: coolingList[index].label,
              });
            }
          }
        }

        setNewZone({
          ...newZone,
          name_zone: '',
          area: '0',
          length: '0',
          plants_distance: '0',
          population: '0',
          hbbcooling_id: '',
          mechanical_id: '',
          plants_id: '',
          tgl_tanam: '',
          monit_env: false,
          monit_media: false,
          monit_ph: false,
          monit_npk: false,
        });
        setListMechanics(arrMec);
        setMecZone(mechData);
        setListPlant(arrPlant);
        setPlantZone(plantData);
        setCoolingZone(coolingData);
        setFarmZone(zone);
        setTempFarmZone(zone);
      })
      .catch(error => {
        console.log(`error ${error}`);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const fetchAgronomist = async () => {
    setIsLoading(true);
    const Authorization = `Bearer ${token}`;

    await axios
      .all([
        newAPI.get(`/global/v1/users`, {headers: {Authorization}}),
        newAPI.get(`/global/v1/farms/${farmId}/bookmark/employee`, {
          headers: {Authorization},
        }),
        stomataAPI.get(`/api/v1/tasks/query?farmcode=${farm?.code_farm}`, {
          headers: {token},
        }),
      ])
      .then(res => {
        console.log({
          res1: res[0],
          res2: res[1],
          res3: res[2],
        });
        const resEmployees = res[0].data.data.filter(
          val => val.role_id === 7 || val.role_id === 6
        );
        const restructureEmployees = [];
        resEmployees.forEach(element => {
          restructureEmployees.push({
            value: element.id,
            label: `${element.first_name} ${element.last_name}`,
          });
        });
        setEmployees(restructureEmployees);
        setChooseEmployees(
          res[1].data?.data?.employees ? res[1].data?.data?.employees : []
        );
        setListTasks(res[2].data?.data);
      })
      .catch(error => console.error(error))
      .finally(() => {
        setIsLoading(false);
      });
  };

  const fetchStoreZone = params => {
    setIsLoading(true);
    // console.log("add");

    const body = new FormData();
    body.append('in_zone', JSON.stringify(newZone));
    body.append('number_zone', farmZone.length + 1);

    const status = [];
    const judul = [];
    if (
      newZone.area !== '' &&
      newZone.length !== '' &&
      newZone.name_zone !== '' &&
      newZone.mechanical_id !== '' &&
      newZone.plants_id !== '' &&
      newZone.plants_distance !== '' &&
      newZone.population !== ''
    ) {
      if (farmZone.length + 1 <= 4) {
        judul.push('Apakah anda yakin untuk menambah zona?');
        status.push('sukses');
      } else {
        judul.push('Apakah anda yakin ingin menambah lebih dari 4 zona?');
        status.push('sukses');
      }
    } else {
      Swal.fire(
        'Tidak bisa menambah zona',
        'masih terdapat data kosong',
        'error'
      );
      setIsLoading(false);
    }

    if (status[0] === 'sukses') {
      Swal.fire({
        title: judul[0],
        showDenyButton: false,
        showCancelButton: true,
        confirmButtonText: 'Tambah',
        denyButtonText: `Jangan Simpan`,
      }).then(result => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          newAPI
            .post(`/v3/farms/zone/${farmId}`, body, {
              headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${token}`,
                'Content-Type': 'multipart/form-data',
              },
            })
            .then(res => {
              console.log(`berhasil insert ${JSON.stringify(res)}`);
            })
            .catch(error => {
              setIsLoading(false);
              console.log(`error ${error}`);
              Swal.fire('Gagal menambah zona', 'terjadi kesalahan', 'error');
            })
            .finally(() => {
              setIsLoading(false);
              fetchZoneEdit();
              Swal.fire('Tersimpan!', 'zona berhasil ditambahkan', 'success');
            });
        } else {
          setIsLoading(false);
        }
      });
    }
  };

  const fetchZoneUpdate = params => {
    setIsLoading(true);
    // console.log("update");
    // console.log(farmZone);
    const body = new FormData();
    body.append('all_zones', JSON.stringify(farmZone));

    Swal.fire({
      title: 'Apakah anda yakin menyimpan perubahan zona?',
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: 'Simpan',
      denyButtonText: `Jangan Simpan`,
    }).then(result => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        newAPI
          .put(`/v3/farms/zone/${farmId}`, body, {
            headers: {
              Accept: 'application/json',
              Authorization: `Bearer ${token}`,
              'Content-Type': 'multipart/form-data',
            },
          })
          .then(res => {
            Swal.fire('Saved!', 'farm has been updated', 'success');
          })
          .catch(error => {
            setIsLoading(false);
            console.log(`error ${error}`);
            Swal.fire(
              'Changes are not saved',
              'there is something wrong',
              'error'
            );
          })
          .finally(() => {
            setIsLoading(false);
            fetchZoneEdit();
          });
      } else if (result.isDenied) {
        Swal.fire('Perubahan tidak disimpan', '', 'info');
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    });
  };

  const updateFarm = params => {
    setIsLoading(true);

    const nohp = [];
    if (params.modem !== undefined) {
      nohp.push(params.modem);
    } else {
      nohp.push(null);
    }

    const typeLink = [];
    const deviceList = [];
    if (device.link.link_type !== null && device.link.link_type.length !== 0) {
      if (device.link.link_type[0].value !== undefined) {
        const type = device.link.link_type;
        type.forEach(e => {
          typeLink.push(e.value);
        });
        deviceList.push({
          grow: device.grow,
          dose: device.dose,
          hwq: device.hwq,
          hws: device.hws,
          hws_climate: device.hws_climate,
          cam: {
            cam_status: device.cam.cam_status,
            cam_master: device.cam.cam_master,
          },
          link: {
            link_status: device.link.link_status,
            link_type: typeLink,
          },
          bardi: {
            bardi_status: device.bardi.bardi_status,
            bardi_master: device.bardi.bardi_master,
          },
        });
      } else if (device.link.link_type[0].value === undefined) {
        deviceList.push(device);
      }
    } else {
      deviceList.push(device);
    }

    if (
      params.status !== undefined &&
      params.internet.value !== '' &&
      params.method_purchasing !== null &&
      params.owner !== null &&
      params.field_type !== null &&
      params.water_sources !== null
    ) {
      const body = new FormData();
      body.append('name', params.name);
      body.append('device', JSON.stringify(deviceList[0]));
      // body.append('grow_zone', params.grow_zone);
      body.append('status', params.status);
      body.append('hws_sn', params.hws_sn);
      body.append('code_farm', params.code_farm.toUpperCase());
      body.append('method_purchasing', params.method_purchasing.value);
      body.append('farmer', params.farmer);
      body.append('owner', params.owner);
      body.append('internet', params.internet.value);
      body.append('field_type', params.field_type.value);
      body.append('field_height', params.field_height);
      body.append('field_slope', params.field_slope);
      body.append('total_farmer', params.total_farmer);
      body.append('water_sources', params.water_sources.value);
      body.append('power', params.power);
      body.append('garansi', params.garansi);
      body.append('latitude', params.latitude);
      body.append('longitude', params.longitude);
      body.append('modem', nohp[0]);
      body.append('city', params.city);
      body.append('image', image);

      newAPI
        .put(`/global/v1/farms/${farmId}`, body, {
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(res => {
          Swal.fire('Saved!', 'farm has been updated', 'success');
        })
        .catch(error => {
          console.log(`error ${error}`);
          Swal.fire(
            'Changes are not saved',
            'there is something wrong',
            'error'
          );
        })
        .finally(() => {
          setIsLoading(false);
          fetchFarmEdit();
        });
    } else {
      Swal.fire(
        'Tidak bisa menambah zona',
        'masih terdapat data kosong',
        'error'
      );
      setIsLoading(false);
    }
  };

  const updateAgronomist = async params => {
    setIsLoading(true);
    const body = {employees: selectedEmployees};
    await newAPI
      .post(`/global/v1/farms/${farmId}/bookmark/employee`, body, {
        headers: {Authorization: `Bearer ${token}`},
      })
      .then(res => {
        setChooseEmployees(prev => [selectedEmployees, ...prev]);
        setSelectedEmployees(null);
      })
      .catch(error => {
        console.log(`error ${error}`);
      })
      .finally(() => {
        // Swal.fire(
        //   'Tidak bisa menambah zona',
        //   'masih terdapat data kosong',
        //   'error'
        // );
        setIsLoading(false);
      });
  };

  const deleteAgronomist = async id => {
    setIsLoading(true);
    await newAPI
      .delete(`/global/v1/farms/${farmId}/bookmark/employee/${id}`, {
        headers: {Authorization: `Bearer ${token}`},
      })
      .then(res => {
        Swal.fire('Berhasil', 'Data telah terhapus', 'success');
      })
      .catch(error => {
        console.log(`error ${error}`);
      })
      .finally(() => {
        // Swal.fire(
        //   'Tidak bisa menambah zona',
        //   'masih terdapat data kosong',
        //   'error'
        // );
        setIsLoading(false);
      });
  };

  const addTodo = async () => {
    setIsLoading(true);
    const body = {
      type: 'input_file',
      typeFile: chooseTasks.task,
      target: chooseTasks.total,
      farmCode: farmId,
    };

    await stomataAPI
      .post(`/api/v1/tasks`, body, {
        headers: {
          'Content-Type': 'application/json',
          token,
        },
      })
      .then(res => {
        Swal.fire('Berhsail', 'Tugas berhasil ditambahkan', 'success');
        setTasks(prev => [...prev, chooseTasks]);
        setChooseTasks(prev => ({
          task: null,
          total: null,
        }));
      })
      .catch(error => {
        console.log(`error ${error}`);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const addTask = async params => {
    setIsLoading(true);
    const {name, remarks} = params;
    const body = {
      name,
      taskCode: 'agronomist-cultivation',
      farmInfo: {
        code: farm?.code_farm,
        name: farm?.name,
        city: farm?.city,
        zones: farmDetail,
      },
      todos: [],
      remarks,
    };

    await stomataAPI
      .post(`/api/v1/tasks`, body, {
        headers: {
          'Content-Type': 'application/json',
          token,
        },
      })
      .then(res => {
        Swal.fire('Berhasil', 'Tugas berhasil ditambahkan', 'success');
        // setListTasks(prev => [...prev, body]);
        fetchAgronomist();
      })
      .catch(error => {
        console.log(`error ${error}`);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const updateTask = async (type, index) => {
    setIsLoading(true);
    let todos = [];
    const getOldTodos =
      listTasks && activeTask
        ? listTasks.find(e => e._id === activeTask._id).todos
        : [];

    if (type === 'delete') {
      todos = getOldTodos.filter((e, i) => i !== index);
    } else {
      todos = [
        {
          type: 'input_file',
          typeFile: chooseTasks.task,
          target: chooseTasks.total,
          farmCode: farmId,
        },
        ...getOldTodos,
      ];
    }

    await stomataAPI
      .patch(
        `/api/v1/tasks/${activeTask?._id}`,
        {todos},
        {
          headers: {
            'Content-Type': 'application/json',
            token,
          },
        }
      )
      .then(res => {
        Swal.fire('Berhasil', 'Tugas berhasil ditambahkan', 'success');
        // setTasks(prev => [...prev, chooseTasks]);
        fetchAgronomist();
        setChooseTasks(prev => ({
          task: null,
          total: null,
        }));
      })
      .catch(error => {
        console.log(`error ${error}`);
      })
      .finally(() => {
        setIsLoading(false);
        setIsModalVisible(false);
      });
  };

  const fetchFarmIsAvailable = async farmCode => {
    setIsLoading(false);
    if (farmCode === farmDevice.farms[0].code_farm) {
      clearErrors('farm_code_used');
      return;
    }
    await newAPI
      .get(`/v3/farms/exist/${farmCode}`, {
        headers: {Authorization: `Bearer ${token}`},
      })
      .then(() => {
        clearErrors('farm_code_used');
      })
      .catch(error => {
        setError('farm_code_used', error);
        console.error(`error ${error}`);
      })
      .finally(() => setIsLoading(false));
  };

  const handleCheckFarmCode = async event => {
    const farmCode = event.target.value;
    await fetchFarmIsAvailable(farmCode);
  };

  useEffect(() => {
    fetchFarmInfo();
  }, [token]);

  const fetchDataFarm = async () => {
    const plantData = [];
    await axios
      .get(`${newURI}/compreport`)
      .then(res => {
        res.data.forEach(e => {
          // var newArrID = []
          // for(var i = 0; i <)

          if (e.farm.id === parseInt(farmId, 10)) {
            const dataTanaman = e.plant.data_plant;

            const arrDataPlant = [];
            for (let i = 0; i < dataTanaman.length; i += 1) {
              const growthId = e.plant.data_plant[i].id;
              const farmIdPlant = e.plant.data_plant[i].farms_id;
              const idPlant = e.plant.data_plant[i].plants_id;
              const typePlant = e.plant.data_plant[i].type;
              const namePlant = e.plant.data_plant[i].plants_name;
              const param = JSON.parse(e.plant.data_plant[i].parameters);

              arrDataPlant.push({
                id: growthId,
                farms_id: farmIdPlant,
                plants_id: idPlant,
                type: typePlant,
                plants_name: namePlant,
                parameters: param,
              });
            }

            plantData.push({
              id: e.farm.id,
              farm_code: e.farm.code_farm,
              plant: arrDataPlant,
              dataparam: e.plant.data_param,
            });

            const namePlant = plantData.map(item => item.plant);
            let newArrPlant = [];
            for (let i = 0; i < namePlant.length; i += 1) {
              newArrPlant = newArrPlant.concat(namePlant[i]);
            }

            const farmKode = plantData.map(item => item.farm_code);

            setDataPlant(newArrPlant);
            // setCodeFarm(farmKode);
          }
        });
        setDataFarm(plantData);
      })
      .catch(error => {
        console.log(`error ${error}`);
      });
  };

  const handleStateParam = async () => {
    await axios
      .get(`${newURI}/compreport`)
      .then(res => {
        // console.log(idGrowth);
        res.data.forEach(e => {
          if (e.farm.id === parseInt(farmId, 10)) {
            const plantData = e.plant.data_plant;
            console.log(plantData);
            const arrStateParam = [];
            const arrPlantName = [];
            for (let i = 0; i < plantData.length; i += 1) {
              if (e.plant.data_plant[i].id === parseInt(idGrowth, 10)) {
                arrPlantName.push(e.plant.data_plant[i].plants_name);
                const lengthParam = JSON.parse(
                  e.plant.data_plant[i].parameters
                );
                for (let index = 0; index < lengthParam.length; index += 1) {
                  arrStateParam.push(false);
                }
              } else {
                arrStateParam.push();
              }
            }
            // console.log(arrPlantName);

            const HandleDataPlant = plantData.filter(
              item => item.id === parseInt(idGrowth, 10)
            );
            const HandlePlantName = HandleDataPlant.map(
              item => item.plants_name
            );
            const HandleGrowthId = HandleDataPlant.map(item => item.id);
            const HandleParam = HandleDataPlant.map(item =>
              JSON.parse(item.parameters)
            );
            let newArrParam = [];
            for (let i = 0; i < HandleParam.length; i += 1) {
              newArrParam = newArrParam.concat(HandleParam[i]);
            }

            setHandleGrowthId(HandleGrowthId);
            setPlantName(HandlePlantName);
            setParameter(newArrParam);
            setStateParam(arrStateParam);
            setTruePlant(arrPlantName.toString());
          }
        });
      })
      .catch(error => {
        console.log(`error ${error}`);
      });
  };

  const handlePlant = async () => {
    setParameter([]);
    setTruePlant([]);
  };

  useEffect(() => {
    handleStateParam();
  }, [idGrowth]);

  useEffect(() => {
    handlePlant();
  }, [resellect]);

  const ParameterCompare = () => (
    <>
      {parameter.map((x, i) => (
        <div key={x} className="row" style={{marginBottom: '4px'}}>
          <div className="col-md-12">
            <label>
              <input
                type="checkbox"
                defaultChecked={stateParam[i]}
                onChange={e => {
                  const baru = stateParam;
                  baru[i] = e.target.checked;
                  setStateParam([...baru]);
                }}
              />
              <span style={{marginLeft: '4px'}}>{x}</span>
            </label>
          </div>
        </div>
      ))}
    </>
  );

  const ChoosePlant = () => (
    <>
      <div className="col-md-12">
        <label>
          Pilih Tanaman
          <select
            name="range"
            className="form-select form-control"
            onChange={e => {
              setIdGrowth(e.target.value);
              handleStateParam();
              setResellect(1);
            }}>
            <option value="kos">Pilih salah satu</option>
            {dataPlant.map(e => (
              <option
                key={e.id}
                selected={plantName.toString() === e.plants_name ? null : false}
                value={e.id}>
                {e.plants_name}
              </option>
            ))}
          </select>
        </label>
      </div>
    </>
  );

  const PlantCompare = () => (
    <>
      <div className="col-12">
        <Card title="Parameter Pertumbuhan">
          <div className="row" style={{marginBottom: '4px'}}>
            {!resellect && <ChoosePlant />}
            {resellect && (
              <button
                type="button"
                onClick={() => setResellect(0)}
                className="btn btn-cyan">
                Pilih Tanaman
              </button>
            )}
          </div>
          <br />
          <p>
            <b>Tanaman:&nbsp;&nbsp;</b>
            <span className="badge bg-red">
              <b>{truePlant}</b>
            </span>
          </p>
          {parseInt(idGrowth, 10) ===
            parseInt(handleGrowthId.toString(), 10) && <ParameterCompare />}
        </Card>
      </div>
    </>
  );

  const handleFetch = params => {
    setMenuSensor('current');

    switch (params) {
      case 'info':
        fetchFarmInfo();
        break;

      case 'edit':
        // fetchUsers();
        fetchFarmEdit();
        break;

      case 'set_zone':
        fetchZoneEdit();
        break;

      case 'agronomist':
        console.log('agronomist');
        fetchAgronomist();
        break;

      case 'sensor':
        break;

      case 'config':
        break;

      case 'report':
        break;

      default:
        break;
    }

    setMenu(params);
  };

  const handleInputChange = (e, index) => {
    // console.log(e);
    if (e.target !== undefined) {
      const {name, value} = e.target;
      const list = [...farmZone];
      list[index][name] = value;
      setFarmZone(list);
    } else {
      const {name, label, value} = e;
      const list = [...farmZone];
      list[index][name] = value;

      console.log(list);
      setFarmZone(list);
    }
  };

  const handleRemoveClick = index => {
    const list = [...farmZone];
    const listMec = [...mecZone];
    if (list.length > 1) {
      list.splice(index, 1);
      listMec.splice(index, 1);
    }
    console.log(list);
    console.log(listMec);
    // setAddZones(addZones - 1)
    setFarmZone(list);
    setMecZone(listMec);
  };

  // const handleAddClick = () => {
  //   setAddZones(addZones + 1)
  //   // console.log(addZones);
  //   setFarmZone([...farmZone, {
  //     // zone_id: `${kodeFarm}_${moment().format('DDMMYYHHmmss')}`,
  //     name_zone: '',
  //     area: '0',
  //     length: '0',
  //     mechanical_id: '',
  //     tgl_tanam: '',
  //     monit_env: false,
  //     monit_media: false,
  //     monit_ph: false,
  //     monit_npk: false,
  //     number_zone: farmZone.length
  //   }]);
  //   // setMecZone([...mecZone, { name: "mechanical_id", value: '', label: '' }])
  // };

  const windDirDesc = item => {
    let winddir;

    if (item === 'U') winddir = 'Utara';
    else if (item === 'TL') winddir = 'Timur Laut';
    else if (item === 'TTL') winddir = 'Timur Timur Laut';
    else if (item === 'T') winddir = 'Timur';
    else if (item === 'TM') winddir = 'Timur Menenggara';
    else if (item === 'TG') winddir = 'Tenggara';
    else if (item === 'SSE') winddir = 'Selatan Menenggara';
    else if (item === 'S') winddir = 'Selatan';
    else if (item === 'SBD') winddir = 'Selatan Barat Daya';
    else if (item === 'BD') winddir = 'Barat Daya';
    else if (item === 'BBD') winddir = 'Barat Barat Daya';
    else if (item === 'B') winddir = 'Barat';
    else if (item === 'BBL') winddir = 'Barat Barat Laut';
    else if (item === 'BL') winddir = 'Barat Laut';
    else if (item === 'UBL') winddir = 'Utara Barat Laut';
    else winddir = 'Tidak Diketahui';

    return winddir;
  };

  const formAddZone = () => (
    <>
      <div>
        <Card title="Tambah Zona">
          <div className="row row-decks row-cards">
            {/* Number Zone */}
            <div
              className="col-md-12 d-flex justify-content-end"
              style={{marginBottom: '0px', marginTop: '10px'}}>
              <span
                className="badge bg-primary"
                style={{fontSize: '20px', padding: '5px'}}>
                Zona {farmZone.length + 1}
              </span>
            </div>

            {/* Nama Zona */}
            <div className="col-md-6">
              <label htmlFor="true" className="form-label">
                Nama Zona
                <span style={{color: 'red'}}>*</span>
              </label>
              <input
                ref={register({required: true})}
                // value={e.name_zone}
                onChange={item =>
                  setNewZone({...newZone, name_zone: item.target.value})
                }
                name="name_zone"
                type="text"
                className="form-control"
                placeholder="Masukan nama zona"
              />
              {errors.name_zone && (
                <div className="pt-1" style={{color: 'red'}}>
                  Nomor zona wajib diisi
                </div>
              )}
            </div>

            {/* Tanaman */}
            <div className="col-md-6">
              <label htmlFor="true" className="form-label">
                Tanaman
                <span style={{color: 'red'}}>*</span>
              </label>
              <Select
                options={listPlant}
                placeholder="Pilih tanaman"
                name="plant_zone"
                onChange={e => {
                  setValue('plant_zone', e.value);
                  setNewZone({...newZone, plants_id: e.value});
                }}
              />
              {errors.plant_zone && (
                <div className="pt-1" style={{color: 'red'}}>
                  Tanaman wajib diisi
                </div>
              )}
            </div>

            {/* Luas Zona */}
            <div className="col-md-6">
              <label htmlFor="true" className="form-label">
                Luas Zona (m²)
                <span style={{color: 'red'}}>*</span>
              </label>
              <input
                ref={register({required: true})}
                value={newZone.area}
                onChange={item =>
                  setNewZone({...newZone, area: item.target.value})
                }
                name="area"
                type="number"
                className="form-control"
                placeholder="Masukan luas zona"
              />
              {errors.area && (
                <div className="pt-1" style={{color: 'red'}}>
                  Luas zona wajib diisi
                </div>
              )}
            </div>

            {/* Panjang Bedengan */}
            <div className="col-md-6">
              <label htmlFor="true" className="form-label">
                Panjang Bedengan (m)
                <span style={{color: 'red'}}>*</span>
              </label>
              <input
                ref={register({required: true})}
                value={newZone.length}
                onChange={item =>
                  setNewZone({...newZone, length: item.target.value})
                }
                name="length"
                type="number"
                className="form-control"
                placeholder="Masukan panjang zona"
              />
              {errors.length && (
                <div className="pt-1" style={{color: 'red'}}>
                  Panjang zona wajib diisi
                </div>
              )}
            </div>

            {/* Jarak Tanam */}
            <div className="col-md-6">
              <label htmlFor="true" className="form-label">
                Jarak Tanam (cm)
                <span style={{color: 'red'}}>*</span>
              </label>
              <input
                ref={register({required: true})}
                value={newZone.plants_distance}
                onChange={item =>
                  setNewZone({...newZone, plants_distance: item.target.value})
                }
                name="plants_distance"
                type="number"
                className="form-control"
                placeholder="Masukan jarak tanam"
              />
              {errors.plants_distance && (
                <div className="pt-1" style={{color: 'red'}}>
                  Jarak tanam wajib diisi
                </div>
              )}
            </div>

            {/* Populasi Tanaman */}
            <div className="col-md-6">
              <label htmlFor="true" className="form-label">
                Populasi Tanaman
                <span style={{color: 'red'}}>*</span>
              </label>
              <input
                ref={register({required: true})}
                value={newZone.population}
                onChange={item =>
                  setNewZone({...newZone, population: item.target.value})
                }
                name="population"
                type="number"
                className="form-control"
                placeholder="Masukan populasi tanaman"
              />
              {errors.population && (
                <div className="pt-1" style={{color: 'red'}}>
                  Populasi tanaman wajib diisi
                </div>
              )}
            </div>

            {/* Mekanikal Irigasi */}
            <div className="col-md-6">
              <label htmlFor="true" className="form-label">
                Mekanikal Irigasi
                <span style={{color: 'red'}}>*</span>
              </label>
              <Select
                options={listMechanics}
                placeholder="Pilih mekanikal irigasi"
                // value={mecZone[i]}
                name="mechanical"
                onChange={e => {
                  setValue('mechanical', e.value);
                  setNewZone({...newZone, mechanical_id: e.value});
                }}
                closeMenuOnSelect
              />
              {errors.mechanical && (
                <div className="pt-1" style={{color: 'red'}}>
                  Mekanikal irigasi wajib diisi
                </div>
              )}
            </div>

            {/* Habibi Cooling */}
            <div className="col-md-6">
              <label htmlFor="true" className="form-label">
                Habibi Cooling
                <span style={{color: 'red'}}>*</span>
              </label>
              <Select
                options={coolingList}
                placeholder="Pilih Habibi Cooling"
                // value={mecZone[i]}
                name="hbbcooling"
                onChange={e => {
                  setValue('hbbcooling', e.value);
                  setNewZone({...newZone, hbbcooling_id: e.value});
                }}
              />
            </div>

            {/* Switch Sensor pH */}
            <div
              className="col-md-3 d-flex justify-content-center"
              style={{marginTop: 25}}>
              <label htmlFor="true" className="form-check form-switch">
                <input
                  ref={register()}
                  name="monit_ph"
                  type="checkbox"
                  className="form-check-input"
                  defaultValue="0"
                  onChange={e => {
                    setNewZone({
                      ...newZone,
                      monit_ph: e.target.checked ? true : null,
                    });
                  }}
                />
                <span className="form-check-label">Monitoring pH</span>
              </label>
            </div>

            {/* Switch Monitoring Lingkungan */}
            <div
              className="col-md-3 d-flex justify-content-center"
              style={{marginTop: 25}}>
              <label htmlFor="true" className="form-check form-switch">
                <input
                  ref={register()}
                  name="monit_env"
                  type="checkbox"
                  className="form-check-input"
                  defaultValue="0"
                  onChange={e => {
                    setNewZone({
                      ...newZone,
                      monit_env: e.target.checked ? true : null,
                    });
                  }}
                />
                <span className="form-check-label">Monitoring Lingkungan</span>
              </label>
            </div>

            {/* Switch Monitoring Media Tanam */}
            <div
              className="col-md-3 d-flex justify-content-center"
              style={{marginTop: 25}}>
              <label htmlFor="true" className="form-check form-switch">
                <input
                  ref={register()}
                  name="monit_media"
                  type="checkbox"
                  className="form-check-input"
                  defaultValue="0"
                  onChange={e => {
                    setNewZone({
                      ...newZone,
                      monit_media: e.target.checked ? true : null,
                    });
                  }}
                />
                <span className="form-check-label">Monitoring Media Tanam</span>
              </label>
            </div>

            {/* Switch Sensor NPK */}
            <div
              className="col-md-3 d-flex justify-content-center"
              style={{marginTop: 25}}>
              <label htmlFor="true" className="form-check form-switch">
                <input
                  ref={register()}
                  name="monit_npk"
                  type="checkbox"
                  className="form-check-input"
                  defaultValue="0"
                  onChange={e => {
                    setNewZone({
                      ...newZone,
                      monit_npk: e.target.checked ? true : null,
                    });
                  }}
                />
                <span className="form-check-label">Monitoring NPK</span>
              </label>
            </div>

            <div className="col-md-12">
              <div className="col-md-12" style={{marginBottom: '10px'}}>
                <button
                  className="btn w-100 btn-primary px-5"
                  type="submit"
                  name="new_zone"
                  onClick={() => fetchStoreZone()}>
                  Tambah Zona
                </button>
              </div>
            </div>
          </div>
        </Card>
      </div>
    </>
  );

  const formZone = () => {
    if (farmZone.length !== 0) {
      return (
        <>
          {farmZone.map((e, i) => (
            <div key={e.id}>
              <div className="row row-decks row-cards">
                <div
                  className="col-md-12 d-flex justify-content-end"
                  style={{marginBottom: '0px', marginTop: '10px'}}>
                  <span
                    className="badge bg-primary"
                    style={{fontSize: '20px', padding: '5px'}}>
                    Zona {i + 1}
                  </span>
                </div>

                {/* {tempFarmZone.length !== 0 ? (
                  <div
                    className="col-md-6 d-flex justify-content-end"
                    style={{marginBottom: '0px', marginTop: '4px'}}>
                    <a
                      href="#close"
                      // onClick={() => {
                      //   handleRemoveClick(i);
                      // }}
                      className="ms-auto cursor-pointer text-decoration-none">
                      <Icon
                        className="text-secondary"
                        name="XCircle"
                        size={20}
                      />
                    </a>
                  </div>
                ) : (
                  <div
                    className="col-md-6 d-flex justify-content-end"
                    style={{marginBottom: '0px', marginTop: '4px'}}>
                    <a
                      href="#close"
                      onClick={() => {
                        handleRemoveClick(i);
                      }}
                      className="ms-auto cursor-pointer text-decoration-none">
                      <Icon className="text-danger" name="XCircle" size={20} />
                    </a>
                  </div>
                )} */}

                {/* Nama Zona */}
                <div className="col-md-6">
                  <label htmlFor="true" className="form-label">
                    Nama Zona
                    <span style={{color: 'red'}}>*</span>
                  </label>
                  <input
                    ref={register({required: true})}
                    defaultValue={e.name_zone}
                    onChange={item => handleInputChange(item, i)}
                    name="name_zone"
                    type="text"
                    className="form-control"
                    placeholder="Masukan nama zona"
                  />
                  {errors.name_zone && (
                    <div className="pt-1" style={{color: 'red'}}>
                      Nomor zona wajib diisi
                    </div>
                  )}
                </div>

                {/* Tanaman */}
                <div className="col-md-6">
                  <label htmlFor="true" className="form-label">
                    Tanaman
                    <span style={{color: 'red'}}>*</span>
                  </label>
                  <Select
                    options={listPlant}
                    placeholder="Pilih tanaman"
                    defaultValue={listPlant && plantZone[i]}
                    name="plant_zone"
                    onChange={item => {
                      handleInputChange(item, i);
                    }}
                  />
                  {errors.plant_zone && (
                    <div className="pt-1" style={{color: 'red'}}>
                      Tanaman wajib diisi
                    </div>
                  )}
                </div>

                {/* Luas Zona */}
                <div className="col-md-6">
                  <label htmlFor="true" className="form-label">
                    Luas Zona (m²)
                    <span style={{color: 'red'}}>*</span>
                  </label>
                  <input
                    ref={register({required: true})}
                    defaultValue={e.area}
                    onChange={item => handleInputChange(item, i)}
                    name="area"
                    type="number"
                    className="form-control"
                    placeholder="Masukan luas zona"
                  />
                  {errors.area && (
                    <div className="pt-1" style={{color: 'red'}}>
                      Luas zona wajib diisi
                    </div>
                  )}
                </div>

                {/* Panjang Bedengan */}
                <div className="col-md-6">
                  <label htmlFor="true" className="form-label">
                    Panjang Bedengan (m)
                    <span style={{color: 'red'}}>*</span>
                  </label>
                  <input
                    ref={register({required: true})}
                    defaultValue={e.length}
                    onChange={item => handleInputChange(item, i)}
                    name="length"
                    type="number"
                    className="form-control"
                    placeholder="Masukan panjang bedengan"
                  />
                  {errors.length && (
                    <div className="pt-1" style={{color: 'red'}}>
                      Panjang bedengan wajib diisi
                    </div>
                  )}
                </div>

                {/* Jarak Tanam */}
                <div className="col-md-6">
                  <label htmlFor="true" className="form-label">
                    Jarak Tanam (cm)
                    <span style={{color: 'red'}}>*</span>
                  </label>
                  <input
                    ref={register({required: true})}
                    defaultValue={e.plants_distance}
                    onChange={item => handleInputChange(item, i)}
                    name="plants_distance"
                    type="number"
                    className="form-control"
                    placeholder="Masukan jarak tanam"
                  />
                  {errors.plants_distance && (
                    <div className="pt-1" style={{color: 'red'}}>
                      Jarak tanam wajib diisi
                    </div>
                  )}
                </div>

                {/* Populasi Tanaman */}
                <div className="col-md-6">
                  <label htmlFor="true" className="form-label">
                    Populasi Tanaman
                    <span style={{color: 'red'}}>*</span>
                  </label>
                  <input
                    ref={register({required: true})}
                    defaultValue={e.population}
                    onChange={item => handleInputChange(item, i)}
                    name="population"
                    type="number"
                    className="form-control"
                    placeholder="Masukan populasi tanaman"
                  />
                  {errors.population && (
                    <div className="pt-1" style={{color: 'red'}}>
                      Populasi tanaman wajib diisi
                    </div>
                  )}
                </div>

                {/* Mekanikal Irigasi */}
                <div className="col-md-6">
                  <label htmlFor="true" className="form-label">
                    Mekanikal Irigasi
                    <span style={{color: 'red'}}>*</span>
                  </label>
                  <Select
                    options={listMechanics}
                    placeholder="Pilih mekanikal irigasi"
                    defaultValue={listMechanics && mecZone[i]}
                    name="mechanical"
                    onChange={item => {
                      handleInputChange(item, i);
                    }}
                    closeMenuOnSelect
                  />
                  {errors.mechanical && (
                    <div className="pt-1" style={{color: 'red'}}>
                      Mekanikal irigasi wajib diisi
                    </div>
                  )}
                </div>

                {/* Habibi Cooling */}
                <div className="col-md-6">
                  <label htmlFor="true" className="form-label">
                    Habibi Cooling
                    <span style={{color: 'red'}}>*</span>
                  </label>
                  <Select
                    options={coolingList}
                    placeholder="Pilih Habibi Cooling"
                    defaultValue={coolingZone[i]}
                    name="hbbcooling"
                    onChange={item => {
                      handleInputChange(item, i);
                    }}
                  />
                </div>

                {/* Switch Sensor pH */}
                <div
                  className="col-md-3 d-flex justify-content-center"
                  style={{marginTop: 25}}>
                  <label htmlFor="true" className="form-check form-switch">
                    <input
                      ref={register()}
                      name="monit_ph"
                      type="checkbox"
                      className="form-check-input"
                      checked={e.monit_ph && 1}
                      defaultValue="0"
                      onChange={item =>
                        handleInputChange(
                          {
                            name: 'monit_ph',
                            value: item.target.checked,
                            label: 'Monit PH',
                          },
                          i
                        )
                      }
                    />
                    {/* <div className="d-flex justify-content-center"> */}
                    <span className="form-check-label">Monitoring pH</span>
                    {/* </div> */}
                  </label>
                </div>

                {/* Switch Monitoring Lingkungan */}
                <div
                  className="col-md-3 d-flex justify-content-center"
                  style={{marginTop: 25}}>
                  <label htmlFor="true" className="form-check form-switch">
                    <input
                      ref={register()}
                      name="monit_env"
                      type="checkbox"
                      className="form-check-input"
                      checked={e.monit_env && 1}
                      defaultValue="0"
                      onChange={item =>
                        handleInputChange(
                          {
                            name: 'monit_env',
                            value: item.target.checked,
                            label: 'Monit Env',
                          },
                          i
                        )
                      }
                    />
                    {/* <div className="d-flex justify-content-center"> */}
                    <span className="form-check-label">
                      Monitoring Lingkungan
                    </span>
                    {/* </div> */}
                  </label>
                </div>

                {/* Switch Monitoring Media Tanam */}
                <div
                  className="col-md-3 d-flex justify-content-center"
                  style={{marginTop: 25}}>
                  <label htmlFor="true" className="form-check form-switch">
                    <input
                      ref={register()}
                      name="monit_media"
                      type="checkbox"
                      className="form-check-input"
                      checked={e.monit_media && 1}
                      defaultValue="0"
                      onChange={item =>
                        handleInputChange(
                          {
                            name: 'monit_media',
                            value: item.target.checked,
                            label: 'Monit Media',
                          },
                          i
                        )
                      }
                    />
                    {/* <div className="d-flex justify-content-center"> */}
                    <span className="form-check-label">
                      Monitoring Media Tanam
                    </span>
                    {/* </div> */}
                  </label>
                </div>

                {/* Switch Sensor NPK */}
                <div
                  className="col-md-3 d-flex justify-content-center"
                  style={{marginTop: 25}}>
                  <label htmlFor="true" className="form-check form-switch">
                    <input
                      ref={register()}
                      name="monit_npk"
                      type="checkbox"
                      className="form-check-input"
                      checked={e.monit_npk && 1}
                      defaultValue="0"
                      onChange={item =>
                        handleInputChange(
                          {
                            name: 'monit_npk',
                            value: item.target.checked,
                            label: 'Monit NPK',
                          },
                          i
                        )
                      }
                    />
                    {/* <div className="d-flex justify-content-center"> */}
                    <span className="form-check-label">Monitoring NPK</span>
                    {/* </div> */}
                  </label>
                </div>

                <div>
                  <hr style={{marginTop: 10, marginBottom: 10}} />
                </div>
              </div>
            </div>
          ))}
        </>
      );
    }
    return '';
  };

  const header = () => (
    <>
      <div className="page-header d-print-none">
        <div className="row align-items-center">
          <button
            aria-label="Back Button"
            type="button"
            onClick={() => history.push('/farm')}
            className="col-2"
            style={{
              marginLeft: '8px',
              marginRight: '10px',
              height: 40,
              width: 40,
              borderRadius: 20,
              backgroundColor: 'rgba(255, 0, 0, 0)',
              borderWidth: 0,
            }}>
            <Icon name="ArrowLeft" color="#333" size={20} />
          </button>
          <div className="col">
            <div className="page-pretitle">Detail Kebun</div>
            <h2 className="page-title">{farm.name}</h2>
          </div>
        </div>
      </div>
    </>
  );

  const sidebar = () => (
    <div className="col-md-3">
      <div className="row row-deck row-cards">
        {/* Menu */}
        <div className="col-12">
          <Card title="Menu" styleBody={{padding: '12px', textAlign: 'center'}}>
            <>
              <button
                type="button"
                className={`btn my-1 w-100 ${
                  menu === 'info' ? 'btn-blue' : ''
                }`}
                onClick={() => handleFetch('info')}>
                Info Kebun
              </button>

              {user.status === ADMIN && (
                <>
                  <button
                    type="button"
                    className={`btn my-1 w-100 ${
                      menu === 'edit' ? 'btn-blue' : ''
                    }`}
                    onClick={() => handleFetch('edit')}>
                    Kelola Kebun
                  </button>
                </>
              )}

              {(user.status === ADMIN || user.status === OWNER) && (
                <>
                  <button
                    type="button"
                    className={`btn my-1 w-100 ${
                      menu === 'set_zone' ? 'btn-blue' : ''
                    }`}
                    onClick={() => handleFetch('set_zone')}>
                    Kelola Zona
                  </button>
                </>
              )}

              <button
                type="button"
                className={`btn my-1 w-100 ${
                  menu === 'sensor' ? 'btn-blue' : ''
                }`}
                onClick={() => handleFetch('sensor')}>
                Data
              </button>

              <button
                type="button"
                className={`btn my-1 w-100 ${
                  menu === 'report' ? 'btn-blue' : ''
                }`}
                onClick={() => handleFetch('report')}>
                Laporan
              </button>

              {(user.status === ADMIN || user.status === AGRONOMIST) && (
                <>
                  {/* <button
                    type="button"
                    className={`btn my-1 w-100 ${menu === 'config' ? 'btn-blue' : ''}`}
                    onClick={() => handleFetch('config')}
                  >
                    Konfigurasi
                  </button> */}

                  {/* <button
                    type="button"
                    className={`btn my-1 w-100 ${menu === 'activity' ? 'btn-blue' : ''}`}
                    onClick={() => setMenu('activity')}
                  >
                    Aktifitas
                  </button> */}

                  <button
                    type="button"
                    className={`btn my-1 w-100 ${
                      menu === 'agronomist' ? 'btn-blue' : ''
                    }`}
                    onClick={() => handleFetch('agronomist')}>
                    Agronomis
                  </button>

                  {/* <button
                    type="button"
                    className={`btn my-1 w-100 ${menu === 'log' ? 'btn-blue' : ''}`}
                    onClick={() => setMenu('log')}
                  >
                    Log
                  </button> */}
                </>
              )}
            </>
          </Card>
        </div>

        {/* Menu Sensor */}
        {menu === 'sensor' ? (
          <div className="col-12">
            <Card title="Menu Data">
              <>
                {/* Sensor */}
                <div className="col-md-12">
                  <select
                    name="articleCategory"
                    className="form-select form-control"
                    defaultValue="current"
                    onChange={e => setMenuSensor(e.target.value)}>
                    <option disabled>Pilih salah satu</option>
                    <option value="current">Kondisi Terkini</option>
                    <option value="ground">Habibi Climate</option>
                    <option value="aws">Habibi Weather Station</option>
                    <option value="dose">Habibi Dose</option>
                    <option value="allsensor">Custom Data</option>
                    <option value="growth">Data Pertumbuhan</option>
                  </select>
                </div>
              </>
            </Card>
          </div>
        ) : null}

        {/* Menu Laporan Start */}
        {menu === 'report' ? (
          <>
            <div className="col-12">
              <Card title="Navigasi Laporan">
                <>
                  {/* Pertumbuhan */}
                  <div className="d-flex align-items-center">
                    <div>Laporan Pertumbuhan</div>
                    <div className="ms-auto">
                      <select
                        name="articleCategory"
                        className="form-select form-control"
                        onChange={e => {
                          setMenuReport(e.target.value);
                          fetchDataFarm();
                        }}>
                        <option value=" ">Pilih salah satu</option>
                        <option value="laporan-mingguan">
                          Laporan Mingguan
                        </option>
                        <option value="compare">Compare Pertumbuhan</option>
                        <option value="origin">Origin Report</option>
                      </select>
                    </div>
                  </div>
                </>
              </Card>
            </div>
            {menuReport === 'compare' ? <PlantCompare /> : <></>}
          </>
        ) : null}
      </div>
    </div>
  );

  const mapsView = () => (
    <div className="col-md-6">
      <GoogleMap
        mapTypeId="satellite"
        center={{
          lat: latitude,
          lng: long,
        }}
        mapContainerClassName="card-body d-inline-block rounded mobile-100"
        zoom={15}>
        <Marker position={{lat: latitude, lng: long}} />
      </GoogleMap>
    </div>
  );

  const tabInfo = () => (
    <>
      <div className="col-md-9">
        <div className="row row-deck row-cards">
          {/* Google Maps */}
          {isLoaded ? mapsView() : <LoadingView />}

          {/* Info Kebun */}
          <div className="col-md-6">
            <Card styleBody={{padding: 0}}>
              <>
                <img
                  src={`${newURI}/farms/${farm.img}`}
                  className="mx-auto d-block my-4 farmDetail_img-circle"
                  alt="Logo Kebun"
                />

                <table className="table table-striped">
                  <tbody>
                    <tr>
                      <td>Kode Kebun</td>
                      <td>{farm.code_farm ? farm.code_farm : '-'}</td>
                    </tr>
                    <tr>
                      <td>Tanggal isi pulsa</td>
                      <td>{farm.tgl_isi_pulsa ? farm.tgl_isi_pulsa : '-'}</td>
                    </tr>
                    <tr>
                      <td>No. Modem</td>
                      <td>{farm.phone ? farm.phone : '-'}</td>
                    </tr>
                    <tr>
                      <td>Provider</td>
                      <td>{farm.provider ? farm.provider : '-'}</td>
                    </tr>
                    <tr>
                      <td>Kota</td>
                      <td>{farm.city ? farm.city : '-'}</td>
                    </tr>
                  </tbody>
                </table>
              </>
            </Card>
          </div>

          {/* Forecast */}
          <div className="col-md-12">
            <Card
              title="Ramalan Cuaca"
              classNameHeader="text-center"
              styleBody={{
                paddingTop: 0,
                paddingBottom: 0,
                paddingLeft: 8,
                paddingRight: 8,
              }}>
              <div className="row">
                <table className="table table-striped pb-0 mb-0">
                  <thead>
                    <tr>
                      <th className="ps-3">Waktu</th>
                      <th className="ps-3">Suhu</th>
                      <th className="ps-3">Kelembaban</th>
                      <th className="ps-3">Kecepatan & Arah Angin</th>
                      <th className="ps-3" width="40%">
                        Keterangan
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {forecast &&
                      forecast.daypartName.map((item, index) => {
                        if (index >= 2) {
                          return (
                            <tr key={item}>
                              <td className="text-center align-middle pt-0">
                                <img
                                  alt="weather"
                                  src={`${process.env.PUBLIC_URL}/dist/img/weather/${forecast.precipType[index]}.svg`}
                                />
                                <br />
                                <b className="align-middle text-capitalize">
                                  {item}
                                </b>
                              </td>
                              <td className="align-middle">
                                {forecast.temperature[index]}°C
                              </td>
                              <td className="align-middle">
                                {forecast.relativeHumidity[index]}%
                              </td>
                              <td className="align-middle">
                                {forecast.windSpeed[index]} km/jam,{' '}
                                {windDirDesc(
                                  forecast.windDirectionCardinal[index]
                                )}{' '}
                                ({forecast.windDirection[index]}°)
                              </td>
                              <td className="align-middle">
                                {forecast.narrative[index]}
                              </td>
                            </tr>
                          );
                        }
                        return <tr key={item} />;
                      })}
                  </tbody>
                </table>
              </div>
            </Card>
          </div>
          {/* <pre>{JSON.stringify(forecast, null, 2)}</pre> */}

          {farmDetail.length >= 1 ? (
            <h3 className="col-12 mb-0" style={{marginTop: 30}}>
              Daftar Zona
            </h3>
          ) : (
            <div />
          )}

          {farmZone.map((item, index) => (
            <div className="col-md-6" key={item.id}>
              <Card
                title={`Zona ${item.number_zone}`}
                classNameHeader="text-center"
                // footer={item.petani}
                styleBody={{padding: 0}}
                styleFooter={{color: '#333', textAlign: 'center'}}>
                <table className="table table-striped">
                  <tbody>
                    <tr>
                      <td>Luas (m²)</td>
                      <td>{item.area}</td>
                    </tr>
                    <tr>
                      <td>Panjang (m)</td>
                      <td>{item.length}</td>
                    </tr>
                    {/* <tr>
                      <td>Tanaman</td>
                      <td>{plant[index].label}</td>
                    </tr> */}
                    <tr>
                      <td>Mekanikal</td>
                      <td>
                        {item.mechanical_id !== null
                          ? mecZone[index].label
                          : '-'}
                      </td>
                    </tr>
                    {/* <tr>
                      <td>Tgl Tanam</td>
                      <td>{item.planting_date ? item.planting_date : '-'}</td>
                    </tr>
                    <tr>
                      <td>Tgl Panen</td>
                      <td>{item.harvest ? item.harvest : '-'}</td>
                    </tr> */}
                  </tbody>
                </table>
              </Card>
            </div>
          ))}
        </div>
      </div>
    </>
  );

  const tabSensor = () => {
    let title = '';

    switch (menuSensor) {
      case 'current':
        title = 'Kondisi Terkini';
        break;

      case 'ground':
        title = 'Sensor Tanah (Habibi Climate)';
        break;

      case 'aws':
        title = 'Sensor Cuaca (Habibi Weather Station)';
        break;

      case 'dose':
        title = 'Dose (Habibi Dose)';
        break;

      case 'allsensor':
        title = 'Custom Data';
        break;

      case 'growth':
        title = 'Pertumbuhan';
        break;

      default:
        title = '';
        break;
    }

    return (
      <>
        <div className="col-md-9">
          <div className="row row-deck row-cards">
            {/* Title */}
            <div className="col-md-12">
              <div className="card-body py-0 border-bottom">
                <h3 style={{marginBottom: 13, marginTop: 13}}>
                  {title}{' '}
                  {title === 'Custom Data' ? (
                    <span style={{color: '#ff0000'}}>(Beta)</span>
                  ) : (
                    <></>
                  )}
                </h3>
              </div>
            </div>

            {/* Content */}
            {menuSensor === 'current' ? (
              <Current farmCode={farm.code_farm} />
            ) : (
              <></>
            )}
            {menuSensor === 'ground' ? (
              <SensorGroundNew farmCode={farm.code_farm} groundData={ground} />
            ) : (
              <></>
            )}
            {menuSensor === 'aws' ? (
              <SensorAwsNew farmCode={farm.code_farm} />
            ) : (
              <></>
            )}
            {menuSensor === 'dose' ? (
              <SensorDoseNew farmCode={farm.code_farm} />
            ) : (
              <></>
            )}
            {menuSensor === 'allsensor' ? (
              <Sensor farmCode={farm.code_farm} />
            ) : (
              <></>
            )}
            {menuSensor === 'growth' ? <Growth /> : <></>}
          </div>
        </div>
      </>
    );
  };

  const tabConfig = () => (
    <>
      <div className="col-md-9">
        <div className="row row-deck row-cards text-center">
          <p style={{marginTop: 100}}>Menu ini sedang dalam pengembangan</p>
          {/* <ul>
            <li>Changing farm status</li>
            <li>Create panel for add & deleting devices such as dose, aws in farm field</li>
            <li>Create panel for adding serial number aws to farm field</li>
          </ul> */}
        </div>
      </div>
    </>
  );

  const tabLM = () => {
    // untuk tabel laporan-laporan mingguan
    const columnLM = [
      {
        Header: 'No',
        accessor: 'no',
        width: '10%',
        Cell: cellInfo => <span>{cellInfo.row.index + 1}</span>,
      },
      {
        Header: 'Fase Tanaman',
        accessor: 'plant_phase',
        Filter: SelectColumnFilter,
        filter: 'includes',
        width: '40%',
      },
      {
        Header: 'Tanggal',
        accessor: 'created_at',
        Filter: SelectColumnFilter,
        filter: 'includes',
        width: '40%',
      },
      {
        Header: '',
        accessor: 'action',
        Filter: false,
        disableSorting: true,
        Cell: cellInfo =>
          handleActionLM(farmId, cellInfo.row.original.id_report),
      },
    ];
    // end untuk tabel laporan-laporan mingguan

    return (
      <>
        <Card title="Form Pertumbuhan">
          <form onSubmit={handleSubmit(insertLaporanMingguan)}>
            <div className="row row-decks row-cards">
              {/* tanggal start */}
              <div className="col-md-12">
                <label htmlFor="true" className="form-label">
                  Tanggal Laporan
                </label>
                <DatePicker
                  className="form-control datepicker"
                  dateFormat="dd/MM/yyyy"
                  selectsRange
                  startDate={startDate}
                  endDate={endDate}
                  onChange={update => {
                    console.log(update);
                    setDateRange(update);
                    setValue(
                      'tanggalLaporan',
                      update.map(tanggal =>
                        tanggal
                          ? moment(tanggal).format('DD-MM-YYYY')
                          : moment(update[0]).format('DD-MM-YYYY')
                      )
                    );
                  }}
                  required
                />
              </div>
              {/* tanggal end */}

              {/* select tanaman start */}
              <div className="col-md-4">
                <label htmlFor="true" className="form-label">
                  Komoditas
                </label>
                <select
                  ref={register()}
                  name="selectTanaman"
                  className="form-control">
                  {SelectTanaman(dataSelectTanaman)}
                </select>
              </div>
              {/* select tanaman end */}

              {/* Sistem start */}
              <div className="col-md-4">
                <label htmlFor="true" className="form-label">
                  Sistem
                </label>
                <select
                  ref={register()}
                  name="sistemKebun"
                  className="form-control">
                  <option value="online">Online</option>
                  <option value="offline">Offline</option>
                </select>
              </div>
              {/* Sistem end */}

              {/* Habibi Climate start */}
              <div className="col-md-4">
                <label htmlFor="true" className="form-label">
                  Habibi Climate
                </label>
                <select
                  ref={register()}
                  name="habibiClimate"
                  className="form-control">
                  <option value="online">Online</option>
                  <option value="offline">Offline</option>
                </select>
              </div>
              {/* Habibi Climate end */}

              {/* Fase Tanaman start */}
              <div className="col-md-3">
                <label htmlFor="true" className="form-label">
                  Fase Tanaman
                </label>
                <select
                  ref={register()}
                  name="faseTanaman"
                  className="form-control">
                  <option value="generatif">Generatif</option>
                  <option value="vegetatif">Vegetatif</option>
                </select>
              </div>
              {/* Fase Tanaman end */}

              {/* Produksi Kebun start */}
              <div className="col-md-3">
                <label htmlFor="true" className="form-label">
                  Produksi Kebun
                </label>
                <select
                  ref={register()}
                  name="produksiKebun"
                  className="form-control">
                  <option value="Buah">Buah</option>
                  <option value="Sayur">Sayur</option>
                </select>
              </div>
              {/* Produksi Kebun end */}

              {/* HST start */}
              <div className="col-md-3">
                <label htmlFor="true" className="form-label">
                  HST
                </label>
                <input
                  ref={register({required: true})}
                  name="hst"
                  className="form-control"
                  type="number"
                  min="0"
                  max="1000"
                  defaultValue="0"
                />
              </div>
              {/* HST end */}

              {/* Jumlah Tanaman start */}
              <div className="col-md-3">
                <label htmlFor="true" className="form-label">
                  Jumlah Tanaman
                </label>
                <input
                  ref={register({required: true})}
                  name="jumlahTanaman"
                  className="form-control"
                  type="number"
                  min="0"
                  max="1000000"
                  defaultValue="0"
                />
              </div>
              {/* Jumlah Tanaman end */}

              {/*  Kendala start */}
              <div className="col-md-12">
                <label htmlFor="true" className="form-label">
                  Kendala
                </label>
                <CKEditor
                  editor={ClassicEditor}
                  data=""
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setValue('kendalaContent', data);
                  }}
                  config={{
                    toolbar: [
                      'heading',
                      '|',
                      'bold',
                      'italic',
                      'link',
                      'bulletedList',
                      'numberedList',
                      '|',
                      'outdent',
                      'indent',
                      '|',
                      'blockQuote',
                      'insertTable',
                      'mediaEmbed',
                      'undo',
                      'redo',
                    ],
                    heading: {
                      options: [
                        {
                          model: 'paragraph',
                          title: 'Paragraph',
                          class: 'ck-heading_paragraph',
                        },
                        {
                          model: 'heading1',
                          view: 'h1',
                          title: 'Heading 1',
                          class: 'ck-heading_heading1',
                        },
                        {
                          model: 'heading2',
                          view: 'h2',
                          title: 'Heading 2',
                          class: 'ck-heading_heading2',
                        },
                        {
                          model: 'heading3',
                          view: 'h3',
                          title: 'Heading 3',
                          class: 'ck-heading_heading3',
                        },
                      ],
                    },
                  }}
                />
              </div>
              {/* Kendala end */}

              {/*  Budidaya start */}
              <div className="col-md-12">
                <label htmlFor="true" className="form-label">
                  Budidaya
                </label>
                <CKEditor
                  editor={ClassicEditor}
                  data=""
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    // setBudidaya(data);
                    setValue('budidayaContent', data);
                  }}
                  config={{
                    toolbar: [
                      'heading',
                      '|',
                      'bold',
                      'italic',
                      'link',
                      'bulletedList',
                      'numberedList',
                      '|',
                      'outdent',
                      'indent',
                      '|',
                      'blockQuote',
                      'insertTable',
                      'mediaEmbed',
                      'undo',
                      'redo',
                    ],
                    heading: {
                      options: [
                        {
                          model: 'paragraph',
                          title: 'Paragraph',
                          class: 'ck-heading_paragraph',
                        },
                        {
                          model: 'heading1',
                          view: 'h1',
                          title: 'Heading 1',
                          class: 'ck-heading_heading1',
                        },
                        {
                          model: 'heading2',
                          view: 'h2',
                          title: 'Heading 2',
                          class: 'ck-heading_heading2',
                        },
                        {
                          model: 'heading3',
                          view: 'h3',
                          title: 'Heading 3',
                          class: 'ck-heading_heading3',
                        },
                      ],
                    },
                  }}
                />
              </div>
              {/* Budidaya end */}

              {/*  Kesimpulan start */}
              <div className="col-md-12">
                <label htmlFor="true" className="form-label">
                  Kesimpulan
                </label>
                <CKEditor
                  editor={ClassicEditor}
                  data=""
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setValue('kesimpulanContent', data);
                  }}
                  config={{
                    toolbar: [
                      'heading',
                      '|',
                      'bold',
                      'italic',
                      'link',
                      'bulletedList',
                      'numberedList',
                      '|',
                      'outdent',
                      'indent',
                      '|',
                      'blockQuote',
                      'insertTable',
                      'mediaEmbed',
                      'undo',
                      'redo',
                    ],
                    heading: {
                      options: [
                        {
                          model: 'paragraph',
                          title: 'Paragraph',
                          class: 'ck-heading_paragraph',
                        },
                        {
                          model: 'heading1',
                          view: 'h1',
                          title: 'Heading 1',
                          class: 'ck-heading_heading1',
                        },
                        {
                          model: 'heading2',
                          view: 'h2',
                          title: 'Heading 2',
                          class: 'ck-heading_heading2',
                        },
                        {
                          model: 'heading3',
                          view: 'h3',
                          title: 'Heading 3',
                          class: 'ck-heading_heading3',
                        },
                      ],
                    },
                  }}
                />
              </div>
              {/* Kesimpulan end */}

              {/* input gambar start */}
              <div className="col-md-6">
                <label htmlFor="true" className="form-label">
                  Gambar (Maks 5MB)
                </label>
                <input
                  ref={register}
                  name="gambarLaporan"
                  accept=".png, .jpg, .jpeg"
                  type="file"
                  className="form-control"
                  onChange={e => {
                    const inputUser = e.target.files[0];
                    // cek apakah input user adalah gambar
                    if (!/^image/.test(inputUser.type)) {
                      alert(`Uploaded file is not an image!`);
                      e.target.value = null;
                      return;
                    }
                    // cek apakah size gambar lebih dari 5 mb
                    if (inputUser.size > 5242880) {
                      alert(`Image size is more than 5 MB !`);
                      e.target.value = null;
                      return;
                    }

                    setImagePreview(inputUser);
                  }}
                />
              </div>
              {/* input gambar end */}

              {/* preview image start */}
              <div className="col-md-12">
                {imagePreview !== null && (
                  <>
                    <label className="form-label">
                      <strong>
                        {imagePreview.name},
                        {(imagePreview.size / 1024).toFixed(2)} KB
                      </strong>
                    </label>
                    <img
                      className="img-thumbnail rounded mx-auto d-block"
                      src={URL.createObjectURL(imagePreview)}
                      alt="Preview Gambar"
                      style={{maxWidth: '300px', maxHeight: '300px'}}
                    />
                    <br />
                  </>
                )}
              </div>
              {/* preview image end */}

              {/* tombol submit start */}
              <div className="col">
                <button className="btn btn-primary " type="submit">
                  SUBMIT
                </button>
              </div>
              {/* tombol submit end */}
            </div>
          </form>
        </Card>
        <Card styleBody={{padding: 0, paddingTop: 20}}>
          <HabibiTable
            title="Laporan Mingguan"
            columns={columnLM}
            data={dataLM}
            formOne={1}
            formTwo={2}
            formThree={null}
            rowSize={[10, 30, 50]}
          />
        </Card>
      </>
    );
  };

  const tabReport = () => {
    let title = '';
    switch (menuReport) {
      case 'compare':
        title = 'Laporan Perbandingan Pertumbuhan';
        break;

      case 'origin':
        title = 'Origin Report';
        break;

      case 'laporan-mingguan':
        title = 'Laporan Mingguan';
        break;

      default:
        title = '';
        break;
    }

    return (
      <>
        <div className="col-md-9">
          <div className="row row-deck row-cards">
            {/* Title */}
            <div className="col-md-12">
              <div className="card-body py-0 border-bottom">
                <h3 style={{marginBottom: 13, marginTop: 13}}>{title}</h3>
              </div>
            </div>

            {/* Content */}
            {/* {menuReport === 'compare' ? <CompareReport parameterIdGrowth={idGrowth} parameterData={stateParam} IdFarm={farmId} codeFarm={codeFarm} handlePlant={resellect} /> : <></>} */}
            {menuReport === 'origin' ? (
              <Report farmCode={farm.code_farm} />
            ) : (
              <></>
            )}

            {/* editan manual hasani */}

            {menuReport === 'laporan-mingguan' ? tabLM() : <></>}

            {/* akhir editan manual hasani */}
          </div>
        </div>
      </>
    );
  };

  const tabAgronomist = () => (
    <>
      <div className="col-md-9">
        <div className="row row-decks row-cards">
          {user.status === ADMIN && (
            <div className="co-md-12">
              <Card title="Agronomis">
                <div className="row row-decks row-cards">
                  <div className="col-md-10">
                    <Select
                      placeholder="Pilih agronomis"
                      options={employees.filter(
                        item =>
                          !chooseEmployees.some(i => i.value === item.value)
                      )}
                      value={selectedEmployees}
                      onChange={e => setSelectedEmployees(e)}
                    />
                    {/* {errors.link_type && (
                      <div className="pt-1" style={{color: 'red'}}>
                        Tipe link wajib dipilih
                      </div>
                    )} */}
                  </div>
                  <div className="col-md-2">
                    <input
                      className="w-100 btn btn-success"
                      type="button"
                      value="Tambah"
                      onClick={updateAgronomist}
                    />
                  </div>

                  <div className="col-md-12">
                    <table className="table table-striped pb-0 mb-0">
                      <thead>
                        <tr>
                          <th className="" width="85%">
                            Nama
                          </th>
                          <th className="" width="15%">
                            Opsi
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {chooseEmployees.map((item, index) => (
                          <tr key={`${item.value}-${index.toString()}`}>
                            <td className="align-middle pt-0 text-capitalize">
                              {item.label}
                            </td>
                            <td className="align-middle">
                              <button
                                className="btn btn-danger"
                                type="button"
                                onClick={() => {
                                  deleteAgronomist(item.value);
                                  fetchAgronomist();
                                }}>
                                Hapus
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </Card>
            </div>
          )}

          <div className="co-md-12">
            <Card
              title="Kelola Tugas"
              footer={
                <div className="col-md-12">
                  {listTasks.map((item, index) => (
                    <Card
                      key={`${item.name}-${index.toString()}`}
                      title={`${item.name} (${item.remarks})`}
                      leftContent={
                        <div className="d-flex">
                          <div className="me-3">
                            <span className="badge bg-primary">
                              {index + 1}
                            </span>
                          </div>
                        </div>
                      }
                      rightContent={
                        user.status === ADMIN && (
                          <div className="ms-auto">
                            <button
                              className="btn btn-primary"
                              type="button"
                              onClick={() => {
                                setIsModalVisible(true);
                                setActiveTask(item);
                                setModalOption('addTask');
                              }}>
                              <Icon
                                className="text-secondary"
                                name="List"
                                size={20}
                                color="#ffffff"
                              />
                            </button>
                          </div>
                        )
                      }
                      className={`${
                        listTasks.length === index + 1 ? 'mb-0' : 'mb-3'
                      }`}>
                      <div className="d-flex row row-decks row-cards">
                        <div className="col-md-4">
                          <Select
                            placeholder="Pilih jenis tugas"
                            options={labelTasks.filter(task =>
                              item?.todos.some(i => i.typeFile === task.value)
                            )}
                            value={labelTasks.find(
                              task => task.value === fileReport.typeFile
                            )}
                            onChange={e =>
                              setFileReport(prev => ({...prev, typeFile: e}))
                            }
                          />
                        </div>
                        <div className="col-md-5">
                          <input
                            type="file"
                            className="form-control"
                            placeholder="Masukan file"
                            onChange={e => {
                              setFileReport(prev => ({
                                ...prev,
                                file: e.target.files[0],
                              }));
                            }}
                          />
                        </div>
                        <div className="d-flex col-md-3 justify-content-end">
                          <input
                            className="btn btn-success"
                            disabled={!fileReport.file || !fileReport.typeFile}
                            type="button"
                            value="Upload Report"
                            onClick={async () => {
                              await uploadFileReport(
                                token,
                                item,
                                fileReport,
                                user
                              );
                              await fetchAgronomist();
                            }}
                          />
                        </div>

                        <div className="col-md-12">
                          <table className="table table-striped pb-0 mb-0">
                            <thead>
                              <tr>
                                <th className="text-start" width="45%">
                                  Nama
                                </th>
                                <th className="text-center" width="15%">
                                  Total
                                </th>
                                <th className="text-center" width="15%">
                                  Target
                                </th>
                                <th className="text-center" width="25%">
                                  Daftar Dokumen
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {item?.todos?.map((todo, idx) => (
                                <tr
                                  key={`${todo.id}-${idx.toString()}`}
                                  className="align-middle">
                                  <td className="text-start">
                                    {
                                      labelTasks.find(
                                        task => todo.typeFile === task.value
                                      ).label
                                    }
                                  </td>
                                  <td className="text-center">
                                    {todo.progress?.length || 0}
                                  </td>
                                  <td className="text-center">{todo.target}</td>
                                  <td className="text-center">
                                    <button
                                      className="btn btn-primary"
                                      type="button"
                                      onClick={() => {
                                        setIsModalVisible(true);
                                        setModalOption('documentList');
                                        setActiveTask(item);
                                        setDocumentList(todo.progress);
                                      }}>
                                      Lihat
                                    </button>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </Card>
                  ))}
                </div>
              }>
              {/* tasks */}
              {user.status === ADMIN ? (
                <form onSubmit={handleSubmit(addTask)}>
                  <div className="row row-decks row-cards">
                    <div className="col-md-6">
                      <input
                        ref={register({required: true})}
                        name="name"
                        type="text"
                        className="form-control"
                        placeholder="Masukan nama tugas"
                      />
                      {errors.name && (
                        <div className="pt-1" style={{color: 'red'}}>
                          Nama tugas wajib diisi
                        </div>
                      )}
                    </div>
                    <div className="col-md-4">
                      <input
                        ref={register()}
                        name="remarks"
                        type="text"
                        className="form-control"
                        placeholder="Masukan catatan"
                      />
                    </div>
                    <div className="col-md-2">
                      <input
                        className="w-100 btn btn-success"
                        type="submit"
                        value="Tambah"
                      />
                    </div>
                  </div>
                </form>
              ) : (
                <div>
                  <span>
                    {listTasks.length > 0
                      ? 'Berikut adalah daftar tugas yang perlu diselesaikan'
                      : 'Tidak ada tugas yang perlu diselesaikan'}
                  </span>
                </div>
              )}
            </Card>
          </div>
        </div>
      </div>
    </>
  );

  const tabEdit = () => (
    <>
      <div className="col-md-9">
        <form onSubmit={handleSubmit(updateFarm)}>
          <div className="row row-decks row-cards">
            <Card title="Kelola Kebun">
              <div className="row row-decks row-cards">
                {/* nama kebun */}
                <div className="col-md-6">
                  <label htmlFor="true" className="form-label">
                    Nama Kebun
                    <span style={{color: 'red'}}>*</span>
                  </label>
                  <input
                    ref={register({required: true})}
                    defaultValue={farmDevice && farmDevice.farms[0].name}
                    name="name"
                    type="text"
                    className="form-control"
                    placeholder="Masukan nama kebun"
                  />
                  {errors.name && (
                    <div className="pt-1" style={{color: 'red'}}>
                      Nama kebun wajib diisi
                    </div>
                  )}
                </div>

                {/* kode kebun */}
                <div className="col-md-6">
                  <label htmlFor="true" className="form-label">
                    Kode Kebun
                    <span style={{color: 'red'}}>*</span>
                  </label>
                  <input
                    ref={register({required: true})}
                    name="code_farm"
                    defaultValue={farmDevice && farmDevice.farms[0].code_farm}
                    type="text"
                    className="form-control text-uppercase"
                    placeholder="Masukan kode kebun"
                    onBlur={handleCheckFarmCode}
                    // onChange={item => console.log((item.target.value).toUpperCase())}
                  />
                  {errors.farm_code_used && (
                    <div className="pt-1" style={{color: 'red'}}>
                      Kode kebun sudah digunakan
                    </div>
                  )}
                  {errors.code_farm && (
                    <div className="pt-1" style={{color: 'red'}}>
                      kode kebun wajib diisi
                    </div>
                  )}
                </div>

                {/* Kota */}
                <div className="col-md-6">
                  <label htmlFor="true" className="form-label">
                    Kota
                    <span style={{color: 'red'}}>*</span>
                  </label>
                  <input
                    ref={register({required: true})}
                    defaultValue={farmDevice && farmDevice.farms[0].city}
                    name="city"
                    type="text"
                    className="form-control"
                    placeholder="Masukan Kota"
                  />
                  {errors.city && (
                    <div className="pt-1" style={{color: 'red'}}>
                      Kota wajib diisi
                    </div>
                  )}
                </div>

                {/* Status */}
                <div className="col-md-6">
                  <label htmlFor="true" className="form-label">
                    Status
                    <span style={{color: 'red'}}>*</span>
                  </label>
                  <Select
                    options={[
                      {value: 1, label: 'Aktif'},
                      {value: 0, label: 'Tidak aktif'},
                    ]}
                    defaultValue={
                      farmDevice && farmDevice.farms[0].status === 1
                        ? {value: 1, label: 'Aktif'}
                        : {value: 0, label: 'Tidak aktif'}
                    }
                    closeMenuOnSelect
                    onChange={e => {
                      // console.log(e.value);
                      setValue('status', e.value);
                    }}
                  />
                  {errors.status && (
                    <div className="pt-1" style={{color: 'red'}}>
                      Status wajib diisi
                    </div>
                  )}
                </div>

                {/* Internet */}
                <div
                  className={
                    detailFarm.internet.value === '' ||
                    detailFarm.internet.value === 'wifi'
                      ? 'col-md-6'
                      : 'col-md-3'
                  }>
                  <label htmlFor="true" className="form-label">
                    Internet
                    <span style={{color: 'red'}}>*</span>
                  </label>
                  <Select
                    options={[
                      {value: 'wifi', label: 'Wifi'},
                      {value: 'hbblink', label: 'Habibi Link'},
                    ]}
                    defaultValue={farmDevice && detailFarm.internet}
                    onChange={e => {
                      // console.log(e.value);
                      setValue('internet', {value: e.value, label: e.label});
                      setDetailFarm({
                        ...detailFarm,
                        internet: {value: e.value, label: e.label},
                      });
                    }}
                  />
                </div>

                {/* Nomer HP */}
                {detailFarm.internet.value === 'hbblink' ? (
                  <div className="col-md-3">
                    <label htmlFor="true" className="form-label">
                      No. Kuota Internet
                      <span style={{color: 'red'}}>*</span>
                    </label>
                    <input
                      ref={register({required: true})}
                      defaultValue={farmDevice && farmDevice.farms[0].phone}
                      name="modem"
                      type="text"
                      className="form-control"
                      placeholder="Masukan No. Kuota Internet"
                      disabled={detailFarm.internet && false}
                    />
                    {errors.modem && (
                      <div className="pt-1" style={{color: 'red'}}>
                        No. Kuota Internet wajib diisi
                      </div>
                    )}
                  </div>
                ) : (
                  <></>
                )}

                {/* Metode Pembelian */}
                <div className="col-md-6">
                  <label htmlFor="true" className="form-label">
                    Metode Pembelian
                    <span style={{color: 'red'}}>*</span>
                  </label>
                  <Select
                    options={[
                      {value: 'beli', label: 'Beli'},
                      {value: 'sewa', label: 'Sewa'},
                      {value: 'yarnen', label: 'Bayar Panen'},
                    ]}
                    defaultValue={farmDevice && detailFarm.method_purchasing}
                    onChange={e => {
                      // console.log(e.value);
                      setValue('method_purchasing', {
                        value: e.value,
                        label: e.label,
                      });
                      // setDetailFarm({ ...detailFarm, method_purchasing: {value: e.value, label: e.label} });
                    }}
                  />
                  {errors.method_purchasing && (
                    <div className="pt-1" style={{color: 'red'}}>
                      Metode wajib diisi
                    </div>
                  )}
                </div>

                {/* Pemilik */}
                <div className="col-md-6">
                  <label htmlFor="true" className="form-label">
                    Pemilik
                    <span style={{color: 'red'}}>*</span>
                  </label>
                  <Select
                    options={users.owner}
                    closeMenuOnSelect
                    defaultValue={defaultUsers && defaultUsers.owner}
                    onChange={e => {
                      setValue('owner', e.value);
                    }}
                  />
                  {errors.owner && (
                    <div className="pt-1" style={{color: 'red'}}>
                      Owner wajib diisi
                    </div>
                  )}
                </div>

                {/* Petani */}
                <div className="col-md-6">
                  <label htmlFor="true" className="form-label">
                    Petani
                    <span style={{color: 'red'}}>*</span>
                  </label>
                  <Select
                    options={[{value: 0, label: 'Tidak Ada'}, ...users.farmer]}
                    defaultValue={defaultUsers && defaultUsers.farmer}
                    closeMenuOnSelect
                    onChange={e => {
                      setValue('farmer', e.value);
                    }}
                  />
                  {errors.farmer && (
                    <div className="pt-1" style={{color: 'red'}}>
                      Petani wajib diisi
                    </div>
                  )}
                </div>

                {/* Field type */}
                <div className="col-md-6">
                  <label htmlFor="true" className="form-label">
                    Jenis Lahan
                    <span style={{color: 'red'}}>*</span>
                  </label>
                  <Select
                    options={[
                      {value: 'indoor', label: 'Indoor'},
                      {value: 'outdoor', label: 'Outdoor'},
                    ]}
                    defaultValue={farmDevice && detailFarm.field_type}
                    onChange={e => {
                      // console.log(e.value);
                      setValue('field_type', {value: e.value, label: e.label});
                      // setDetailFarm({ ...detailFarm, field_type: {value: e.value, label: e.label} });
                    }}
                  />
                  {errors.field_type && (
                    <div className="pt-1" style={{color: 'red'}}>
                      Jenis lahan wajib diisi
                    </div>
                  )}
                </div>

                {/* Total farmer */}
                <div className="col-md-6">
                  <label htmlFor="true" className="form-label">
                    Jumlah Anggota Kelompok Tani
                    <span style={{color: 'red'}}>*</span>
                  </label>
                  <input
                    ref={register({required: true})}
                    defaultValue={
                      farmDevice && farmDevice.farms[0].total_farmer
                    }
                    name="total_farmer"
                    type="number"
                    className="form-control"
                    placeholder="Masukan jumlah anggota kelompok tani"
                  />
                  {errors.total_farmer && (
                    <div className="pt-1" style={{color: 'red'}}>
                      Jumlah anggota kelompok tani wajib diisi
                    </div>
                  )}
                </div>

                {/* Field height */}
                <div className="col-md-6">
                  <label htmlFor="true" className="form-label">
                    Ketinggian lahan
                    <span style={{color: 'red'}}>*</span>
                  </label>
                  <input
                    ref={register({required: true})}
                    defaultValue={
                      farmDevice && farmDevice.farms[0].field_height
                    }
                    name="field_height"
                    type="number"
                    className="form-control"
                    placeholder="Masukan ketinggian lahan"
                  />
                  {errors.field_height && (
                    <div className="pt-1" style={{color: 'red'}}>
                      Ketinggian lahan wajib diisi
                    </div>
                  )}
                </div>

                {/* Field slope */}
                <div className="col-md-6">
                  <label htmlFor="true" className="form-label">
                    Kemiringan lahan
                    <span style={{color: 'red'}}>*</span>
                  </label>
                  <input
                    ref={register({required: true})}
                    defaultValue={farmDevice && farmDevice.farms[0].field_slope}
                    name="field_slope"
                    type="number"
                    className="form-control"
                    placeholder="Masukan kemiringan lahan"
                  />
                  {errors.field_slope && (
                    <div className="pt-1" style={{color: 'red'}}>
                      Kemiringan lahan wajib diisi
                    </div>
                  )}
                </div>

                {/* Power */}
                <div className="col-md-6">
                  <label htmlFor="true" className="form-label">
                    Daya Listrik
                    <span style={{color: 'red'}}>*</span>
                  </label>
                  <input
                    ref={register({required: true})}
                    defaultValue={farmDevice && farmDevice.farms[0].power}
                    name="power"
                    type="number"
                    className="form-control"
                    placeholder="Masukan daya listrik"
                  />
                  {errors.power && (
                    <div className="pt-1" style={{color: 'red'}}>
                      Daya Listrik wajib diisi
                    </div>
                  )}
                </div>

                {/* Water sources */}
                <div className="col-md-6">
                  <label htmlFor="true" className="form-label">
                    Sumber Air
                    <span style={{color: 'red'}}>*</span>
                  </label>
                  <Select
                    options={[
                      {value: 'sumur', label: 'Sumur'},
                      {value: 'pdam', label: 'PDAM'},
                      {value: 'sungai', label: 'Sungai'},
                      {value: 'bak', label: 'Bak Penampungan'},
                    ]}
                    defaultValue={detailFarm && detailFarm.water_sources}
                    onChange={e => {
                      // setDetailFarm({ ...detailFarm, water_sources: {value: e.value, label: e.label} });
                      setValue('water_sources', {
                        value: e.value,
                        label: e.label,
                      });
                    }}
                  />
                  {errors.water_sources && (
                    <div className="pt-1" style={{color: 'red'}}>
                      Sumber air wajib diisi
                    </div>
                  )}
                </div>

                {/* Latitude */}
                <div className="col-md-6">
                  <label htmlFor="true" className="form-label">
                    Latitude
                    <span style={{color: 'red'}}>*</span>
                  </label>
                  <input
                    ref={register({required: true})}
                    name="latitude"
                    defaultValue={farmDevice && farmDevice.farms[0].latitude}
                    type="number"
                    className="form-control"
                    placeholder="Masukan Latitude"
                    onChange={item => {
                      setLatitude(parseFloat(item.target.value));
                    }}
                    value={latitude}
                  />
                  {errors.latitude && (
                    <div className="pt-1" style={{color: 'red'}}>
                      Latitude wajib diisi
                    </div>
                  )}
                </div>

                {/* Longitude */}
                <div className="col-md-6">
                  <label htmlFor="true" className="form-label">
                    Longitude
                    <span style={{color: 'red'}}>*</span>
                  </label>
                  <input
                    ref={register({required: true})}
                    defaultValue={farmDevice && farmDevice.farms[0].longitude}
                    name="longitude"
                    type="number"
                    className="form-control"
                    placeholder="Masukan Longitude"
                    onChange={item => {
                      setLong(parseFloat(item.target.value));
                      // console.log(item.target.value)
                    }}
                    value={long}
                  />
                  {errors.longitude && (
                    <div className="pt-1" style={{color: 'red'}}>
                      Longitude wajib diisi
                    </div>
                  )}
                </div>

                {isLoaded && (
                  <div className="col-md-12">
                    <GoogleMap
                      mapContainerStyle={{
                        width: '100%',
                        height: '400px',
                      }}
                      center={{
                        lat: latitude,
                        lng: long,
                      }}
                      zoom={15}
                      mapTypeId="satellite"
                      mapContainerClassName="card-body d-inline-block rounded mobile-100">
                      {/* Child components, such as markers, info windows, etc. */}
                      <>
                        <Marker
                          position={{lat: latitude, lng: long}}
                          onDragEnd={e => {
                            setLatitude(e.latLng.lat());
                            setLong(e.latLng.lng());
                          }}
                          draggable
                        />
                      </>
                    </GoogleMap>
                  </div>
                )}

                {/* gambar */}
                <div className="col-md-6">
                  <label htmlFor="true" className="form-label">
                    Gambar
                  </label>
                  <input
                    ref={register}
                    name="image"
                    type="file"
                    className="form-control"
                    placeholder="Masukan gambar"
                    onChange={e => {
                      setImage(e.target.files[0]);
                      setImagePreview(URL.createObjectURL(e.target.files[0]));
                    }}
                  />
                  {errors.image && (
                    <div className="pt-1" style={{color: 'red'}}>
                      Gambar artikel wajib diisi
                    </div>
                  )}
                </div>

                {/* Garansi */}
                <div className="col-md-6">
                  <label htmlFor="true" className="form-label">
                    Garansi
                    <span style={{color: 'red'}}> (dalam satuan bulan)</span>
                  </label>
                  <input
                    ref={register({required: true})}
                    defaultValue={farmDevice && farmDevice.farms[0].garansi}
                    name="garansi"
                    type="number"
                    className="form-control"
                    placeholder="Masukan garansi"
                  />
                  {errors.garansi && (
                    <div className="pt-1" style={{color: 'red'}}>
                      Garansi wajib diisi
                    </div>
                  )}
                </div>

                {/* preview & input gambar */}
                <div className="col-md-12">
                  {imagePreview && (
                    <img
                      className="col-md-12 mx-auto d-block text-center"
                      alt="pic preview"
                      src={imagePreview}
                      style={{maxWidth: '300px', maxHeight: '300px'}}
                    />
                  )}
                </div>
              </div>
            </Card>
            <Card title="Edit Device Kebun">
              <div className="row row-decks row-cards">
                {/* Grow */}
                <div className="col-md-12">
                  <label htmlFor="true" className="form-check form-switch">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      checked={device.grow && 1}
                      onChange={e => {
                        setDevice({
                          ...device,
                          grow: e.target.checked ? true : null,
                        });
                      }}
                    />
                    <span className="form-check-label">Tambah Habibigrow</span>
                  </label>
                  {errors.grow && (
                    <div className="pt-1" style={{color: 'red'}}>
                      Grow wajib diisi
                    </div>
                  )}
                </div>

                {/* Dose */}
                <div className="col-md-12">
                  <label htmlFor="true" className="form-check form-switch">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      checked={device.dose && 1}
                      onChange={e => {
                        setDevice({
                          ...device,
                          dose: e.target.checked ? true : null,
                        });
                      }}
                    />
                    <span className="form-check-label">Tambah Habibidose</span>
                  </label>

                  {errors.dose && (
                    <div className="pt-1" style={{color: 'red'}}>
                      Dose wajib diisi
                    </div>
                  )}
                </div>

                {/* hws */}
                <div
                  className={
                    device.hws === null || device.hws === false
                      ? 'col-md-12'
                      : 'col-md-6'
                  }>
                  <label htmlFor="true" className="form-check form-switch">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      checked={device.hws && 1}
                      onChange={e => {
                        setDevice({
                          ...device,
                          hws: e.target.checked ? true : null,
                        });
                      }}
                    />
                    <span className="form-check-label">Tambah Habibihws</span>
                  </label>
                  {/* {errors.hws && (
                    <div className="pt-1" style={{ color: 'red' }}>
                      HWS wajib diisi
                    </div>
                  )} */}
                </div>

                {/* HWS Setting */}
                {device.hws && (
                  <div className="col-md-6">
                    <label htmlFor="true" className="form-label">
                      Serial Number HWS
                      <span style={{color: 'red'}}>*</span>
                    </label>
                    <input
                      ref={register({required: true})}
                      name="hws_sn"
                      type="text"
                      defaultValue={
                        farmDevice.hws.length ? farmDevice.hws[0].sn : null
                      }
                      className="form-control"
                      placeholder="Masukan Serial number"
                    />
                    {errors.hws_sn && (
                      <div className="pt-1" style={{color: 'red'}}>
                        Serial number wajib diisi
                      </div>
                    )}
                  </div>
                )}

                {/* hws + climate */}
                <div className="col-md-12">
                  <label htmlFor="true" className="form-check form-switch">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      checked={device.hws_climate && true}
                      onChange={e => {
                        setDevice({
                          ...device,
                          hws_climate: e.target.checked ? true : null,
                        });
                      }}
                    />
                    <span className="form-check-label">
                      Tambah Habibihws + Climate
                    </span>
                  </label>
                  {/* {errors.hws && (
                    <div className="pt-1" style={{ color: 'red' }}>
                      HWS wajib diisi
                    </div>
                  )} */}
                </div>

                {/* habibi water quality */}
                <div className="col-md-12">
                  <label htmlFor="true" className="form-check form-switch">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      checked={device.hwq && 1}
                      onChange={e => {
                        setDevice({
                          ...device,
                          hwq: e.target.checked ? true : null,
                        });
                      }}
                    />
                    <span className="form-check-label">
                      Tambah Habibi Water Quality
                    </span>
                  </label>
                  {errors.hwq && (
                    <div className="pt-1" style={{color: 'red'}}>
                      Habibi Water Quality wajib diisi
                    </div>
                  )}
                </div>

                {/* Habibi cam */}
                <div
                  className={
                    device.cam.cam_status === null ||
                    device.cam.cam_status === false ||
                    device.cam.cam_status === undefined
                      ? 'col-md-12'
                      : 'col-md-6'
                  }>
                  <label htmlFor="true" className="form-check form-switch">
                    <input
                      name="cam"
                      type="checkbox"
                      className="form-check-input"
                      checked={device.cam.cam_status && true}
                      onChange={e => {
                        setDevice({
                          ...device,
                          cam: {
                            ...device.cam,
                            cam_status: e.target.checked ? true : null,
                          },
                        });
                      }}
                    />
                    <span className="form-check-label">Tambah HabibiCam</span>
                  </label>
                </div>

                {/* Hbb Cam Setting */}
                {device.cam.cam_status && (
                  <div className="col-md-6">
                    <label htmlFor="true" className="form-label">
                      Master HabibiCam
                      <span style={{color: 'red'}}>*</span>
                    </label>
                    <input
                      ref={register({required: true})}
                      name="cam_master"
                      type="text"
                      defaultValue={
                        device.cam.cam_status ? device.cam.cam_master : null
                      }
                      onChange={e => {
                        setDevice({
                          ...device,
                          cam: {...device.cam, cam_master: e.target.value},
                        });
                      }}
                      className="form-control"
                      placeholder="Masukan nama"
                    />
                    {errors.cam_master && (
                      <div className="pt-1" style={{color: 'red'}}>
                        Nama wajib diisi
                      </div>
                    )}
                  </div>
                )}

                {/* Habibi link */}
                <div
                  className={
                    device.link.link_status === null ||
                    device.link.link_status === false ||
                    device.link.link_status === undefined
                      ? 'col-md-12'
                      : 'col-md-6'
                  }>
                  <label htmlFor="true" className="form-check form-switch">
                    <input
                      // ref={internet.internet === "hbblink"? register({ required: true }) : register()}
                      name="link"
                      type="checkbox"
                      className="form-check-input"
                      checked={device.link.link_status && true}
                      onChange={e => {
                        setDevice({
                          ...device,
                          link: {
                            ...device.link,
                            link_status: e.target.checked ? true : null,
                          },
                        });
                      }}
                    />
                    <span className="form-check-label">Tambah HabibiLink</span>
                  </label>
                  {errors.link && (
                    <div className="pt-1" style={{color: 'red'}}>
                      Habibi link wajib dipilih
                    </div>
                  )}
                </div>

                {/* Hbb Link Setting */}
                {device.link.link_status && (
                  <div className="col-md-6">
                    <label htmlFor="true" className="form-label">
                      Pilih Link
                      <span style={{color: 'red'}}>*</span>
                    </label>
                    <Select
                      isMulti
                      options={[
                        {value: 'dashboard', label: 'Dashboard'},
                        {value: 'apk', label: 'Aplikasi'},
                      ]}
                      defaultValue={device && device.link.link_type}
                      onChange={e => {
                        const feature = [];
                        for (let i = 0; i < e.length; i += 1) {
                          feature.push(e[i].value);
                        }
                        setValue('link_type', feature);
                        setDevice({
                          ...device,
                          link: {...device.link, link_type: feature},
                        });
                      }}
                    />
                    {errors.link_type && (
                      <div className="pt-1" style={{color: 'red'}}>
                        Tipe link wajib dipilih
                      </div>
                    )}
                  </div>
                )}

                {/* Bardi */}
                <div
                  className={
                    device.bardi.bardi_status === null ||
                    device.bardi.bardi_status === false ||
                    device.bardi.bardi_status === undefined
                      ? 'col-md-12'
                      : 'col-md-6'
                  }>
                  <label htmlFor="true" className="form-check form-switch">
                    <input
                      name="bardi"
                      type="checkbox"
                      className="form-check-input"
                      checked={device.bardi.bardi_status && true}
                      onChange={e => {
                        setDevice({
                          ...device,
                          bardi: {
                            ...device.bardi,
                            bardi_status: e.target.checked ? true : null,
                          },
                        });
                      }}
                    />
                    <span className="form-check-label">Tambah Bardi</span>
                  </label>
                </div>

                {/* Bardi Setting */}
                {device.bardi.bardi_status && (
                  <div className="col-md-6">
                    <label htmlFor="true" className="form-label">
                      Master Bardi
                      <span style={{color: 'red'}}>*</span>
                    </label>
                    <input
                      ref={register({required: true})}
                      name="bardi_master"
                      type="text"
                      defaultValue={
                        device.bardi.bardi_status
                          ? device.bardi.bardi_master
                          : null
                      }
                      onChange={e => {
                        setDevice({
                          ...device,
                          bardi: {
                            ...device.bardi,
                            bardi_master: e.target.value,
                          },
                        });
                      }}
                      className="form-control"
                      placeholder="Masukan nama"
                    />
                    {errors.bardi_master && (
                      <div className="pt-1" style={{color: 'red'}}>
                        Nama wajib diisi
                      </div>
                    )}
                  </div>
                )}

                {/* Grow Setting */}
                {/* {device.grow && (
                  <div className="col-md-6">
                    <label htmlFor="true" className="form-label">
                      Zona Kebun (min 0 zona & max 4 zona)
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      ref={register({ required: true })}
                      name="grow_zone"
                      type="number"
                      defaultValue={device.zone && device.zone}
                      min="0"
                      max="4"
                      className="form-control"
                      placeholder="Masukan jumlah zona"
                    />
                    {errors.grow_zone && (
                      <div className="pt-1" style={{ color: "red" }}>
                        Jumlah zona wajib diisi
                      </div>
                    )}
                  </div>
                )} */}

                <div className="col-md-12">
                  <input
                    className="btn w-100 btn-primary px-5"
                    type="submit"
                    value="Simpan Perubahan"
                    disabled={errors.farm_code_used}
                  />
                </div>
              </div>
            </Card>
          </div>
        </form>
      </div>
    </>
  );

  const tabZone = () => (
    <>
      <div className="col-md-9">
        <div className="row row-decks row-cards">
          {formAddZone()}
          <form onSubmit={handleSubmit(fetchZoneUpdate)}>
            <Card title="Setting Zona">
              <div className="row row-decks row-cards">
                {formZone()}
                <div className="col-md-12">
                  {farmZone.length !== 0 ? (
                    <input
                      className="btn w-100 btn-danger px-5"
                      type="submit"
                      value="Save"
                      name="all_zones"
                    />
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </Card>
          </form>
        </div>
      </div>
    </>
  );

  const handleView = () => {
    switch (menu) {
      case 'info':
        return tabInfo();

      case 'sensor':
        return tabSensor();

      case 'set_zone':
        return tabZone();

      case 'config':
        return tabConfig();

      case 'edit':
        return tabEdit();

      case 'report':
        return tabReport();

      case 'agronomist':
        return tabAgronomist();

      default:
        return null;
    }
  };

  const modalAddTask = () => (
    <div className="row row-decks row-cards">
      <div className="col-md-6">
        <Select
          menuPlacement="auto"
          placeholder="Pilih tugas"
          options={
            activeTask?._id &&
            listTasks.find(f => f._id === activeTask._id).todos.length === 0
              ? labelTasks.filter(
                  item =>
                    !listTasks
                      .find(f => f._id === activeTask._id)
                      .todos.some(i => i.typeFile === item.value)
                )
              : labelTasks
          }
          required
          value={
            chooseTasks.task
              ? {
                  value: chooseTasks.task,
                  label: labelTasks.find(e => e.value === chooseTasks.task)
                    .label,
                }
              : null
          }
          onChange={e => {
            setChooseTasks(prev => ({
              ...prev,
              task: e.value,
            }));
          }}
        />
      </div>
      <div className="col-md-4">
        <input
          type="number"
          className="form-control"
          placeholder="Masukan jumlah"
          value={parseInt(chooseTasks.total, 10)}
          onChange={e => {
            setChooseTasks(prev => ({
              ...prev,
              total: e.target.value,
            }));
          }}
        />
      </div>
      <div className="col-md-2">
        <input
          className="w-100 btn btn-success"
          type="button"
          disabled={!chooseTasks?.task || !chooseTasks?.total}
          value="Tambah"
          onClick={() => updateTask('add')}
        />
      </div>

      <div className="col-md-12">
        <table className="table table-striped pb-0 mb-0">
          <thead>
            <tr>
              <th className="" width="75%">
                Tugas
              </th>
              <th className="" width="15%">
                Jumlah
              </th>
              <th className="" width="10%">
                Opsi
              </th>
            </tr>
          </thead>
          <tbody>
            {listTasks && activeTask
              ? listTasks
                  .find(e => e._id === activeTask._id)
                  .todos.map((item, index) => (
                    <tr key={`${item.typeFile}-${index.toString()}`}>
                      <td className="align-middle text-capitalize">
                        {labelTasks.find(e => e.value === item.typeFile).label}
                      </td>
                      <td className="align-middle">{item.target}</td>
                      <td className="align-middle">
                        <button
                          className="btn btn-danger"
                          type="button"
                          onClick={() => updateTask('delete', index)}>
                          Hapus
                        </button>
                      </td>
                    </tr>
                  ))
              : null}
          </tbody>
        </table>
      </div>
    </div>
  );

  const modalDocumentList = () => (
    <div className="row row-decks row-cards">
      <div className="col-md-12">
        <div style={{overflowY: 'auto', maxHeight: '500px'}}>
          <table className="table table-striped pb-0 mb-0">
            <thead>
              <tr>
                <th className="" width="80%">
                  Nama Dokumen
                </th>
                <th className="" width="20%">
                  Opsi
                </th>
              </tr>
            </thead>

            <tbody>
              {documentList &&
                documentList.map((item, index) => (
                  <tr key={item.fileId}>
                    <td className="align-middle text-capitalize">
                      {item.fileName}
                    </td>
                    <td className="align-middle">
                      <button
                        className="btn btn-primary" // Add Bootstrap margin-right class
                        type="button"
                        style={{marginRight: '10px'}}
                        onClick={async () => {
                          await downloadFileReport(token, item);
                        }}>
                        Download
                      </button>
                      <button
                        className="btn btn-danger"
                        type="button"
                        onClick={async () => {
                          await deleteFileReport(token, activeTask, item);
                          await fetchAgronomist();
                          setIsModalVisible(false);
                        }}>
                        Hapus
                      </button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );

  return (
    <div className="container-xl">
      {header()}

      <div className="row">
        {sidebar()}

        {/* content */}
        {isLoading ? (
          <div className="col-md-9">
            <LoadingView />
          </div>
        ) : (
          handleView()
        )}
      </div>

      <ModalBottom
        isModalOpen={isModalVisible}
        setIsModalOpen={() => setIsModalVisible(!isModalVisible)}>
        {modalOption === 'addTask' ? modalAddTask() : null}
        {modalOption === 'documentList' ? modalDocumentList() : null}
      </ModalBottom>
    </div>
  );
}

export default FarmDetail;
